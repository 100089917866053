import { Box, Typography } from "@mui/material"

const PrivacyView = () =>{
return (
    <Box>
        <Typography variant="h4">Privacy policy</Typography>
    </Box>
)
}

export default PrivacyView