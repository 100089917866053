import Box from "@mui/material/Box"

import Typography from "@mui/material/Typography"
import { forwardRef } from "react"
import { useNavigate } from "react-router-dom"
import { truncated } from "../shared/truncateString"
import useIsMobile from "../shared/useIsMobile"

const BookCard = forwardRef(({ isRelated, gridSize, hideRightBorder, book }, ref) => {
	const navigate = useNavigate()
	const cardStyle = {
		width: "100%",
		height: isRelated ? "auto" : "100%",
		margin: "0 auto",
	}
	if (isRelated){
		hideRightBorder = true
	}

	const titleFontSize = {
		1: { fontSize: "80px", lineHeight: "80px" },
		2: { fontSize: "40px", lineHeight: "40px" },
		3: { fontSize: "26px", lineHeight: "26px" },
		4: { fontSize: "20px", lineHeight: "20px" },
		5: { fontSize: "16px", lineHeight: "16px" },
	}

	const artistFontSize = {
		1: { fontSize: "64px", lineHeight: "64px" },
		2: { fontSize: "32pt", lineHeight: "32px" },
		3: { fontSize: "21px", lineHeight: "21px" },
		4: { fontSize: "16px", lineHeight: "16px" },
		5: { fontSize: "13px", lineHeight: "13px" },
	}
	
	var indexOfFont
	if (useIsMobile()) {
		if (gridSize == 1) {
			indexOfFont = 4
		} else {
			indexOfFont = 5
		}
	} else {
		indexOfFont = gridSize
	}
	if (book && book.thumbnail.url) {
		return (
			<>
				<Box
					borderBottom={1}
					borderRight={hideRightBorder ? 0 : 1}
					style={cardStyle}
				>
					<Box className="book-card-container">
						<Box
							className="book-card-content clickable"
							onClick={() => book.id ? navigate(`/${book.id}`) : undefined}
						>
							<Typography paddingTop={"3px"}
								ref={ref}
								sx={{
									...titleFontSize[indexOfFont],
									...truncated,
								}}
								fontWeight={500}
								color="text.primary"
								className="title"
							>
								{book.title.toUpperCase()}
							</Typography>

							<Typography
								sx={{
									fontWeight: 300,
									...artistFontSize[indexOfFont],
									...truncated,
									lineHeight: 1.1
								}}
								color="text.primary"
								className="artist"
							>
								{book.artist}
							</Typography>

							<Box className="image" height={"75%"}>
								<img alt="thumbnail" src={book.thumbnail.url}></img>

								<Box className="year">
									<Typography
										sx={{
											fontVariantNumeric: "slashed-zero",
											fontWeight: 300,
											...artistFontSize[indexOfFont],
										}}
										color="text.primary"
									>
										{book.year}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</>
		)
	}
})
BookCard.displayName = "Bookcard"
export default BookCard
