import { rgbToHex } from "@mui/material"

export const colors = {
	naturalWhite: rgbToHex("rgb(255,255,255)"),

	white: rgbToHex("rgb(242,242,242)"),

	black: rgbToHex("rgb(0,0,0)"),

	grey: rgbToHex("rgb(138,135,149)"),
}

export const getDesignTokens = (mode) => ({
	palette: {
		mode,
		...(mode === "light"
			? {
					// palette values for light mode
					primary: {
						main: colors.black,
					},
					text: {
						primary: colors.black,
						secondary: colors.grey,
					},
					background: {
						default: colors.naturalWhite,
						inverted: colors.black,
					},
			  }
			: {
					// palette values for dark mode
					primary: {
						main: colors.white,
					},

					text: {
						primary: colors.white,
						secondary: colors.grey,
					},
					background: {
						default: colors.black,
						inverted: colors.naturalWhite,
					},
			  }),
	},


	typography: {
		fontFamily: ["neue-haas-grotesk-display"],
		thin22: {
			fontSize: "22px",
			fontWeight: 200,
			fontStyle: "normal",
			"@media (max-width:1400px)": {
				fontSize: "1.2rem",
			},
			"@media (max-width:1200px)": {
				fontSize: "0.8rem",
			},
		},
		navbarlink: {
			fontSize: "28px",
			fontWeight: 700,
			fontStyle: "light",
			
			"@media (max-width:1000px)": {
				fontSize: "1.6rem",
			},
			"@media (max-width:500px)": {
				fontSize: "1.4rem",
			},
			"@media (max-width:330px)": {
				fontSize: "1.0rem",
			},
		},
		medium22: { fontSize: "22px", fontWeight: "normal" },
		categoryTitle: { fontSize: "22px", fontWeight: 300 },
		thin18: { fontSize: "18px", fontWeight: "lighter" },
		medium18: {
			fontSize: "18px",
			fontWeight: "normal",
		},
		thin16: { fontSize: "16px", fontWeight: "lighter" },
		medium16: { fontSize: "16px", fontWeight: "normal" },
		p: { fontSize: "16px", fontWeight: "normal" },
		h1: { fontSize: "80px", fontWeight: 530 },
		h4: { fontSize: "40px", fontWeight: 530 },
		submitTitle: { fontSize: "22px", fontWeight: 500 },
		aboutSectionTitle: { fontSize: "23px", fontWeight: 500 },
		aboutSectionText: { fontSize: "23px", fontWeight: 200 },
	},
})
