import { Box, Typography, Link } from "@mui/material"
import { NavLink } from "react-router-dom"
import useIsMobile from "./shared/useIsMobile"


const AboutView = () => {

    const isMobile = useIsMobile()

    if (isMobile){
        return (
            <Box 
                display="flex" 
                flexDirection="column" 
                width="100%" 
                padding={2} 
                border={1} 
                borderTop={0}
                boxSizing="border-box"
            >
                <Box 
                    width={{ xs: "100%", sm: "675px" }} 
                    margin="0 auto" 
                    padding={2}
                >
                    <Box padding="4px">
                        <Box>
                            <Typography>
                                If you would like to submit an artbook, please fill out the form 
                                <Link to="/submit" component={NavLink}> here.</Link>
                            </Typography>
                            <Typography>
                                Please read the guidelines below before submitting an artbook.
                            </Typography>
                        </Box>
                        <Box marginTop={2}>
                            <Typography variant="h4" marginBottom={2}>
                                Submission Guidelines
                            </Typography>
                            <Typography>
                                Please send at least 5 images, and up to 15 images maximum (plus thumbnail).
                            </Typography>
                            <Typography>
                                The images can be a maximum of 2000 px in width and a maximum file size of 2 MB.
                            </Typography>
                            <Typography>
                                The thumbnail must be 1200 pixels in width and 1400 pixels in height and a maximum file size of 2 MB.
                            </Typography>
                            <Typography>
                                The following fields in the form are mandatory: title, artist, edition, page count, dimensions, year, description. We hope that you also complete the other fields where applicable.
                            </Typography>
                            <Typography>
                                If you have any questions or problems, please send an E-MAIL to 
                                <Link href="mailto:websiteforfinnishartbooks@gmail.com"> websiteforfinnishartbooks@gmail.com </Link>
                            </Typography>
                            <Typography>
                                Thank you for submitting to Website for Finnish Artbooks!
                            </Typography>
                        </Box>
                    </Box>
                    <Box marginTop={2}>
                        <Typography variant="h4" marginBottom={2}>
                            Website for Finnish Artbooks
                        </Typography>
                        <Typography>
                            Website for Finnish Artbooks presents artbooks. To buy an art book you see here, please contact the relevant artist directly. Any sales are between artist and buyer only. Contact information of the artist is available on Website for Finnish Artbooks where provided by them upon submission. Website for Finnish Artbooks is a non-commercial and non-profit platform.
                        </Typography>
                        <Box marginTop={4}>
                            <Typography>
                                Concept and Admin: Peppi-Lotta Heinonen, Tuomas Linna, Olivia Viitakangas
                            </Typography>
                            <Typography>
                                Design: <Link href="https://peppi-lotta.com" target="_blank" rel="noopener noreferrer">Peppi-Lotta Heinonen</Link>
                            </Typography>
                            <Typography>
                                Development: Kalle Lehikoinen
                            </Typography>
                            <Typography>
                                Thank you: <Link href="https://www.taike.fi/en" target="_blank" rel="noopener noreferrer">Art Promotion Centre Finland</Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box display={"flex"} flexDirection={"row"} width={"100%"} border={1} borderTop={0}>
                <Box width={"46%"} borderRight={1} overflow={"scroll"}>
                   <Box borderBottom={1}>
                        <Box margin={"26px"} marginTop={"40px"}>
                            <Typography fontWeight={500} fontSize={"28px"}> If you would like to submit an artbook, please fill out the form <Link to={"/submit"} component={NavLink} >here. </Link> 
                                Please read the guidelines below before submitting. </Typography> 
                        </Box>
                    </Box>
                    <Box>
                        <Box margin={"26px"} marginTop={"40px"}>
                            <Typography component={"header"} variant="aboutSectionTitle">ARTBOOKS FINLAND</Typography>
                            <Typography component={"text"} variant="aboutSectionText">Artbook Finland presents artbooks. To buy an art book you see here, please contact the relevant artist directly. Any sales are between artist and buyer only. Contact information of the artist is available on Artbooks Finland where provided by them upon submission. Artbooks Finland is a non-commercial and non-profit platform.</Typography>
                            
                            <Typography marginTop={"23px"} component={"header"} variant="aboutSectionTitle">CONCEPT</Typography>
                            <Typography component={"text"} variant="aboutSectionText">Peppi-Lotta Heinonen, Kalle Lehikoinen, Tuomas Linna, Olivia Viitakangas</Typography>
                        
                            <Typography marginTop={"23px"} component={"header"} variant="aboutSectionTitle">DESIGN</Typography>
                            <Typography component={"text"} variant="aboutSectionText"><Link href="https://peppi-lotta.com" target="_blank" rel="noopener noreferrer">Peppi-Lotta Heinonen</Link></Typography>

                            <Typography marginTop={"23px"} component={"header"} variant="aboutSectionTitle">DEVELOPMENT</Typography>
                            <Typography component={"text"} variant="aboutSectionText">Kalle Lehikoinen</Typography>

                            <Typography marginTop={"23px"} component={"header"} variant="aboutSectionTitle">Thank you</Typography>
                            <Typography component={"text"} variant="aboutSectionText"><Link href="https://www.taike.fi/en" target="_blank" rel="noopener noreferrer">Art Promotion Centre Finland</Link></Typography>

                          
                        </Box>
                    </Box>
                </Box>
                <Box width={"54%"} overflow={"scroll"}>
                
                        <Box margin={"50px"} marginTop={"40px"}>
                            <Typography marginTop={"25px"}  component={"header"} fontWeight={600} fontSize={"40px"}>Submission Guidelines</Typography>
                            <Typography display="block" marginTop={"25px"} component={"text"} fontWeight={400} fontSize={"25px"}>Please send at least 5 images and up to 15 images maximum (plus thumbnail).</Typography>
                           
                            <Typography display="block" marginTop={"25px"} component={"text"} fontWeight={400} fontSize={"25px"}>The images can be a maximum of 2000 px in width and a maximum file size of 2 MB.</Typography>
                            <Typography display="block" marginTop={"25px"} component={"text"} fontWeight={400} fontSize={"25px"}>The thumbnail must be 1200 pixels in width and 1400 pixels in height and a maximum file size of 2 MB.</Typography>
                            <Typography display="block" marginTop={"25px"} component={"text"} fontWeight={400} fontSize={"25px"}>Images have to be uploaded in jpg format.</Typography>
                            <Typography display="block" marginTop={"25px"} component={"text"} fontWeight={400} fontSize={"25px"}>The following fields in the form are mandatory: title, artist, edition, page count, dimensions, year, description. We hope that you also complete the other fields where applicable.</Typography>
                            <Typography display="block" marginTop={"25px"} component={"text"} fontWeight={400} fontSize={"25px"}>In the case of an art book by more than one author, please separate the authors&apos; names by comma.</Typography>
                            <Typography display="block" marginTop={"25px"} component={"text"} fontWeight={400} fontSize={"25px"}>If you have any questions or problems, please send an E-MAIL to artbooksfinland@gmail.com</Typography>
                            <Typography display="block" marginTop={"25px"} component={"text"} fontWeight={400} fontSize={"25px"}>Thank you for submitting to Artbooks Finland!</Typography>

                          
                        </Box>
                   
                </Box>
            
        </Box>
    )
}

export default AboutView