export const setModeInStorage = (mode) => {
	localStorage.setItem("mode", mode)
}

export const getModeInStorage = () => {
	return localStorage.getItem("mode")
}

export const setUser = (user) => {
	localStorage.setItem("user", user)
}

export const getUser = () => {
	const user = localStorage.getItem("user")
	return JSON.parse(user)
}

export const removeUser = () => {
	localStorage.removeItem("user")
}

export const setRf = (rf) => {
	localStorage.setItem("rf", rf)
}

export const getRf = () => {
	const rf = localStorage.getItem("rf")
	return rf
}

export const removeRf = () => {
	localStorage.removeItem("rf")
}
