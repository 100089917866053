import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	categoryTags: [],
	manualCategoryTags: {
		genre: "",
		theme: "",
	},
	genres: [],
	themes: [],
	publisherTag: "",
	manualPublisher: "",
	availableToPurchase: false,
	consentApproved: false,
	thumbnail: null,
	images: [],
	fields: {},
}
export const submitBookSlice = createSlice({
	name: "submitBook",
	initialState,
	reducers: {
		setUpFields: (state, action) => {
			state.fields = action.payload
		},
		addThumbnail: (state, action) => {
			state.thumbnail = action.payload
		},
		removeThumbnail: (state) => {
			state.thumbnail = null
		},
		addImage: (state, action) => {
			state.images.push(action.payload)
		},
		removeImage: (state, action) => {
			const id = action.payload
			state.images = state.images.filter((img) => img.id !== id)
		},
		addCategoryTag: (state, action) => {
			const tag = action.payload
			if (tag === "Self-Published"){
				state.publisher = tag
				return
			}
			if (!state.categoryTags.includes(tag)) {
				state.categoryTags.push(tag)
			}
		},
		addManualCategoryTag: (state, action) => {

			const object = action.payload
			if (object.category === "genre"){
				state.manualCategoryTags.genre = object.value
			}else if (object.category === "theme"){
				state.manualCategoryTags.theme = object.value
			}
		},

		setManualPublisher: (state, action) => {
			state.manualPublisher = action.payload
		},
		setPublisherTag: (state, action) => {
			state.publisherTag = action.payload
		},
		setAvailableToPurchase: (state) => {
			state.availableToPurchase = !state.availableToPurchase
		},
		setConsentApproved: (state) => {
			state.consentApproved = !state.consentApproved
		},
		removecategoryTag: (state, action) => {
			const tag = action.payload
			state.categoryTags = state.categoryTags.filter((t) => t !== tag)
		},
		addTheme: (state, action) => {
			const tag = action.payload
			if (!state.themes.includes(tag)) {
				state.themes.push(tag)
			}
		},
		addGenre: (state, action) => {
			const tag = action.payload
			if (!state.genres.includes(tag)) {
				state.genres.push(tag)
			}
		},
		removeTheme: (state, action) => {
			const tag = action.payload
			state.themes = state.themes.filter((t) => t !== tag)
		},
		removeGenre: (state, action) => {
			const tag = action.payload
			state.genres = state.genres.filter((t) => t !== tag)
		},
		resetSubmitBook: (state) => {
			state.categoryTags = []
			state.manualCategoryTags.genre = ""
			state.manualCategoryTags.theme = ""
			state.publisherTag = "",
			state.manualPublisher = "",
			state.availableToPurchase = false
			state.consentApproved = false
			state.fields = {}
			state.thumbnail = null
			state.images = []
			state.genres = []
			state.themes = []
		},
	},
})

export const {
	setUpFields,
	addThumbnail,
	removeThumbnail,
	addImage,
	removeImage,
	addCategoryTag,
	setUseManualCategoryTag,
	setAvailableToPurchase,
	setConsentApproved,
	removecategoryTag,
	addManualCategoryTag,
	resetSubmitBook,
	setManualPublisher,
	setPublisherTag,
	addGenre,
	addTheme,
	removeGenre,
	removeTheme,
} = submitBookSlice.actions

export default submitBookSlice.reducer
