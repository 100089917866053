import { Box } from "@mui/material"
const RightSection = ({ component }) => {
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			width={"47%"}
			borderRight={1}
			overflow={"scroll"}
		>
			{component}
		</Box>
	)
}

export default RightSection
