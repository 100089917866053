import { Box, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import AdminButtons from "./AdminButtons"
import { truncated } from "../shared/truncateString"

const DashBookItem = ({ book }) => {
	const navigate = useNavigate()

	const StatusText = () => {
		const newBookText = book.new ? "(New)" : ""

		return book.published ? (
			<Typography color="green">Published{newBookText}</Typography>
		) : (
			<Typography color="red">Not published {newBookText}</Typography>
		)
	}

	return (
		<Grid borderBottom={1} className="book-list-item" item>
			<Box className="book-list-content">
			<Grid
          item
          xs={0.5}
		  borderRight={1}
          sx={{
            padding: "4px",
            
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%", // Ensure consistent height
            
          }}
        >
          <Box
		  	onClick={() => navigate(`/${book.id}`)}
			className="clickable"
            component="img"
            src={book.thumbnail.url}
            alt="thumbnail"
            sx={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
              padding: "5px",
               // Ensure image padding is accounted for
            }}
          />
        </Grid>
				<Box
					borderRight={1}
					
					className="title clickable"
					onClick={() => navigate(`/${book.id}`)}
				>
					<Typography
						sx={{ fontWeight: 530, ...truncated }}
						color="text.primary"
					>
						{book.title}
					</Typography>
				</Box>
				<Box
					borderRight={1}
					paddingLeft={"1%"}
					display={"flex"}
					alignItems={"center"}
					width={"30%"}
					height={"100%"}
				>
					<Typography
						sx={{
							fontWeight: "lighter",
							...truncated,
						}}
						color="text.secondary"
					>
						{book.artist}
					</Typography>
				</Box>
				<Box
					borderRight={1}
					paddingLeft={"1%"}
					display={"flex"}
					alignItems={"center"}
					width={"15%"}
					height={"100%"}
				>
					<StatusText />
				</Box>
				<Box display={"flex"} width={"8%"} justifyContent={"center"}>
					<AdminButtons book={book} />
				</Box>
			</Box>
		</Grid>
	)
}

export default DashBookItem
