import { IconButton, Tooltip } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import GridImgDark from "../../assets/icons/grid_dark.png"
import GridImg from "../../assets/icons/grid.png"
import ListImgDark from "../../assets/icons/list_dark.png"
import ListImg from "../../assets/icons/list.png"
import BlackCircleImgDark from "../../assets/icons/black_circle_dark.png"
import BlackCircleImg from "../../assets/icons/black_circle.png"
import WhiteCircleImgDark from "../../assets/icons/white_circle_dark.png"
import WhiteCircleImg from "../../assets/icons/white_circle.png"
import MinusImgDark from "../../assets/icons/minus_dark.png"
import MinusImg from "../../assets/icons/minus.png"
import PlusImgDark from "../../assets/icons/plus_dark.png"
import PlusImg from "../../assets/icons/plus.png"
import SearcImgDark from "../../assets/icons/search_dark.png"
import SearchImg from "../../assets/icons/search.png"
import ArrowDownImgDark from "../../assets/icons/arrow_down_dark.png"
import ArrowDownImg from "../../assets/icons/arrow_down.png"
import ArrowUpImgDark from "../../assets/icons/arrow_up_dark.png"
import ArrowUpImg from "../../assets/icons/arrow_up.png"
import XImgDark from "../../assets/icons/x_dark.png"
import XImg from "../../assets/icons/x.png"
import Logo from "../../assets/ABF_LOGO.png"
import LogoDark from "../../assets/ABF_LOGO_dark.png"
import UploadIconIMG from "../../assets/icons/upload.png"
import UploadIconIMGDark from "../../assets/icons/upload_dark.png"
import BackButtonImg from "../../assets/icons/back_to_archive.png"
import BackButtonImgDark from "../../assets/icons/back_to_archive_dark.png"
import DeleteIcon from "@mui/icons-material/Delete"
import PublishIcon from "@mui/icons-material/Publish"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import loadingGif from "../../assets/abf_animaatio.gif"
import loadingGifDark from "../../assets/abf_animaatio_dark.gif"
import HamburgerMenuIcon from "../../assets/icons/Mobiili_navi_hamppari.png"
import HamburgerMenuIconDark from "../../assets/icons/Mobiili_navi_hamppari.png"
import CloseMenuIcon from "../../assets/icons/Mobiili_navi_X.png"
import CloseMenuIconDark from "../../assets/icons/Mobiili_navi_X_dark.png"


import { selectIsLoading } from "../../reducers/loadingSlice"




import {
	toGridView,
	toListView,
	zoomOut,
	zoomIn,
	toLightMode,
	toDarkMode,
} from "../../reducers/layoutSlice"
import { useNavigate } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import {
	addCategoryTag,
	addGenre,
	addManualCategoryTag,
	addTheme,
	removecategoryTag,
	removeGenre,
	removeTheme,
	setAvailableToPurchase,
	setConsentApproved,
	setManualPublisher,
	setPublisherTag,
} from "../../reducers/submitBookSlice"
import { toggleAvailableToPurchase } from "../../reducers/filterSlice"

const iconButtonStyle = {
	"&:hover": { opacity: 0.6 },
	"&.MuiButtonBase-root:hover": {
		bgcolor: "transparent",
	},
}

export function GridIcon({ mode }) {
	const dispatch = useDispatch()
	const imgSrc = mode == "light" ? GridImg : GridImgDark

	return (
			<IconButton onClick={() => dispatch(toGridView())} sx={iconButtonStyle}>
				<img src={imgSrc} height="22" alt="Grid" />
			</IconButton>
	)
}

export function ListIcon({ mode }) {
	const dispatch = useDispatch()

	const imgSrc = mode == "light" ? ListImg : ListImgDark

	return (
			<IconButton onClick={() => dispatch(toListView())} sx={iconButtonStyle}>
				<img src={imgSrc} height="22" alt="List" />
			</IconButton>
	)
}

export function LightModeIcon({ mode }) {
	const dispatch = useDispatch()

	const imgSrc = mode == "light" ? WhiteCircleImg : BlackCircleImgDark

	return (
			<IconButton onClick={() => dispatch(toLightMode())} sx={iconButtonStyle}>
				<img src={imgSrc} height="22" alt="light" />
			</IconButton>
	)
}

export function DarkModeIcon({ mode }) {
	const dispatch = useDispatch()

	const imgSrc = mode == "light" ? BlackCircleImg : WhiteCircleImgDark

	return (
			<IconButton onClick={() => dispatch(toDarkMode())} sx={iconButtonStyle}>
				<img src={imgSrc} height="22" alt="dark" />
			</IconButton>
	)
}

export function MinusIcon({ mode }) {
	const dispatch = useDispatch()

	const imgSrc = mode == "light" ? MinusImg : MinusImgDark

	return (
			<IconButton onClick={() => dispatch(zoomOut())} sx={iconButtonStyle}>
				<img src={imgSrc} height="22" alt="zoomout" />
			</IconButton>
	)
}

export function PlusIcon({ mode }) {
	const dispatch = useDispatch()

	const imgSrc = mode == "light" ? PlusImg : PlusImgDark

	return (
			<IconButton onClick={() => dispatch(zoomIn())} sx={iconButtonStyle}>
				<img src={imgSrc} height="22" alt="zoomin" />
			</IconButton>
	)
}

export function SearchIcon({ mode, handleClick }) {
	const imgSrc = mode == "light" ? SearchImg : SearcImgDark

	return (
			<IconButton onClick={handleClick} sx={iconButtonStyle}>
				<img src={imgSrc} height="22" alt="Grid" />
			</IconButton>
	)
}

export function DropDownOpenIcon({ mode, handleClick }) {
	const imgSrc = mode == "light" ? ArrowDownImg : ArrowDownImgDark

	return (
		<IconButton onClick={handleClick} sx={iconButtonStyle}>
			<img src={imgSrc} height="22"  alt="arrowdown" />
		</IconButton>
	)
}

export function DropDownCloseIcon({ mode, handleClick }) {
	const imgSrc = mode == "light" ? ArrowUpImg : ArrowUpImgDark

	return (
		<IconButton onClick={handleClick} sx={iconButtonStyle}>
			<img src={imgSrc} height="22"  alt="arrowup" />
		</IconButton>
	)
}

export function ScrollToTopIcon({ mode, handleClick }) {
	const imgSrc = mode == "light" ? ArrowUpImg : ArrowUpImgDark
	const scrollButtonStyles = {
		position: "fixed",
		bottom: 12,
		right: 12,
		zIndex: 1000, // Ensure the button appears above other content
	}
	return (
		<IconButton onClick={handleClick} sx={scrollButtonStyles}>
			<img src={imgSrc} width="19" height="16" alt="arrowup" />
		</IconButton>
	)
}

export function XIcon({ mode, handleClick, custom }) {
	let imgSrc = mode == "light" ? XImg : XImgDark
	if (custom) {
		imgSrc = mode == "light" ? XImgDark : XImg
	}

	return (
		<IconButton onClick={handleClick} sx={iconButtonStyle}>
			<img src={imgSrc} width="12" height="12" alt="aclose" />
		</IconButton>
	)
}

export function HamburgerMenu({mode, isOpen}){
	const imgSrc = isOpen
		? mode === "light"
			? CloseMenuIcon
			: CloseMenuIconDark
		: mode === "light"
		? HamburgerMenuIcon
		: HamburgerMenuIconDark

	return (
		<img
			src={imgSrc}
			alt={isOpen ? "Close menu" : "Open menu"}
			height={40}
		/>
	)

	
}



export function PbffLogo({ mode }) {

	const loadingImgSrc = mode == "light" ? loadingGif : loadingGifDark
	const isLoading = useSelector(selectIsLoading)

	const imgSrc = mode == "light" ? Logo : LogoDark

	return <img src={isLoading ? loadingImgSrc : imgSrc} className="logo" alt="pbff-logo" style={{
		maxWidth: "100%",       
		maxHeight: "100%",      
		objectFit: "contain",   
		display: "block",       
	  }}/>
}

export const UploadIcon = ({ mode }) => {
	const imgSrc = mode == "light" ? UploadIconIMG : UploadIconIMGDark
	return <img src={imgSrc} height={50}></img>
}

export function BackButton({ mode, size }) {
	const imgSrc = mode == "light" ? BackButtonImg : BackButtonImgDark
	const navigate = useNavigate()
	return (
		<IconButton onClick={() => navigate("/")} sx={iconButtonStyle}>
			<img src={imgSrc} height={size} alt="arrowup" />
		</IconButton>
	)
}

export function RadioButton({ mode, size, category, item }) {
	//const dispatch = useDispatch()
	const [imgSrc, setImgSrc] = useState()
	const categoryTags = useSelector((state) => state.submitBook.categoryTags)
	const [isSelected, setIsSelected] = useState(categoryTags.includes(item))

	const dispatch = useDispatch()

	useEffect(() => {
		if (mode === "light") {
			if (isSelected) {
				setImgSrc(BlackCircleImg)
			} else {
				setImgSrc(WhiteCircleImg)
			}
		} else {
			if (isSelected) {
				setImgSrc(WhiteCircleImgDark)
			} else {
				setImgSrc(BlackCircleImgDark)
			}
		}
	}, [isSelected, mode])

	const handleClick = () => {
		if (category == "publisher"){
			if (!isSelected) {
				dispatch(setPublisherTag(item))
			} else {
				dispatch(setPublisherTag(""))
			}
			setIsSelected(!isSelected)
		}else{
			if (!isSelected) {
				dispatch(addCategoryTag(item))
				if (category == "theme") {dispatch(addTheme(item))}
				else if (category == "genre") {dispatch(addGenre(item))}
			} else {
				dispatch(removecategoryTag(item))
				if (category == "theme") {dispatch(removeTheme(item))}
				else if (category == "genre") {dispatch(removeGenre(item))}
			}
			setIsSelected(!isSelected)
		}
	}

	return (
		<IconButton onClick={() => handleClick()}>
			<img src={imgSrc} height={size} alt="dark" />
		</IconButton>
	)
}

export function PurchaseRadioButton({ mode, size }) {
	//const dispatch = useDispatch()
	const [imgSrc, setImgSrc] = useState()
	
	const [isSelected, setIsSelected] = useState(false)

	const dispatch = useDispatch()

	useEffect(() => {
		if (mode === "light") {
			if (isSelected) {
				setImgSrc(BlackCircleImg)
			} else {
				setImgSrc(WhiteCircleImg)
			}
		} else {
			if (isSelected) {
				setImgSrc(WhiteCircleImgDark)
			} else {
				setImgSrc(BlackCircleImgDark)
			}
		}
	}, [isSelected, mode])

	const handleClick = () => {
		if (!isSelected) {
			dispatch(setAvailableToPurchase(true))
		} else {
			dispatch(setAvailableToPurchase(false))
		}
		setIsSelected(!isSelected)
	}

	return (
		<IconButton onClick={() => handleClick()}>
			<img src={imgSrc} height={size} alt="dark" />
		</IconButton>
	)
}


export function InputRadioButton({ mode, size, textInput, category }) {
    const [imgSrc, setImgSrc] = useState()
    const [isSelected, setIsSelected] = useState(false)
	const dispatch = useDispatch()
	
    useEffect(() => {
        setIsSelected(textInput !== "")

        if (mode === "light") {
            setImgSrc(isSelected ? BlackCircleImg : WhiteCircleImg)
        } else {
            setImgSrc(isSelected ? WhiteCircleImgDark : BlackCircleImgDark)
        }
    }, [mode, isSelected, textInput])

	useEffect(() => {
		const object = {
			category: category,
			value: textInput
		}
		
		if (category == "publisher"){
			dispatch(setManualPublisher(object.value))
		}else{
			dispatch(addManualCategoryTag(object))
		}
	
    }, [textInput])

    return (
        <IconButton>
            <img src={imgSrc} height={size} alt="radio button" />
        </IconButton>
    )
}



export function DeleteButton({ handleDelete }) {
	return (
		<Tooltip title="Delete">
			<IconButton onClick={() => handleDelete()}>
				<DeleteIcon />
			</IconButton>
		</Tooltip>
	)
}

export const UnpublishBookIcon = ({ handleUnpublish }) => {
	return (
		<>
			<Tooltip title="Unpublish">
				<IconButton onClick={handleUnpublish} aria-label="unpublish">
					<FileDownloadIcon />
				</IconButton>
			</Tooltip>
		</>
	)
}

export const PublishBookIcon = ({ handlePublish }) => {
	return (
		<>
			<Tooltip title="Publish">
				<IconButton onClick={handlePublish} aria-label="publish">
					<PublishIcon />
				</IconButton>
			</Tooltip>
		</>
	)
}

export function ConsentApprovedButton({ mode, size }) {
	//const dispatch = useDispatch()
	const [imgSrc, setImgSrc] = useState()
	
	const [isSelected, setIsSelected] = useState(false)

	const dispatch = useDispatch()

	useEffect(() => {
		if (mode === "light") {
			if (isSelected) {
				setImgSrc(BlackCircleImg)
			} else {
				setImgSrc(WhiteCircleImg)
			}
		} else {
			if (isSelected) {
				setImgSrc(WhiteCircleImgDark)
			} else {
				setImgSrc(BlackCircleImgDark)
			}
		}
	}, [isSelected, mode])

	const handleClick = () => {
		if (!isSelected) {
			dispatch(setConsentApproved(true))
		} else {
			dispatch(setConsentApproved(false))
		}
		setIsSelected(!isSelected)
	}

	return (
		<IconButton onClick={() => handleClick()}>
			<img src={imgSrc} height={size} alt="dark" />
		</IconButton>
	)
}

export function ToggleAvailableToPurchaseButton({ mode, size }) {
	const dispatch = useDispatch()

  // Manage selected state
  const [isSelected, setIsSelected] = useState(false)

  // Use useMemo to avoid unnecessary re-calculation of imgSrc
  const imgSrc = useMemo(() => {
    if (mode === "light") {
      return isSelected ? BlackCircleImg : WhiteCircleImg
    } else {
      return isSelected ? WhiteCircleImgDark : BlackCircleImgDark
    }
  }, [isSelected, mode]) // Dependency array to only recompute when `isSelected` or `mode` changes

  const handleClick = () => {
    const newSelectedState = !isSelected
    setIsSelected(newSelectedState)
    dispatch(toggleAvailableToPurchase(newSelectedState))
  }

  return (
    <IconButton sx={iconButtonStyle} onClick={handleClick}>
      <img src={imgSrc} height={size} alt={mode === "light" ? "light mode" : "dark mode"} />
    </IconButton>
  )
}
