import { Box, Typography } from "@mui/material"
import { InputField, Title, useField } from "../book-details/Components"
import { DropDownCloseIcon } from "../shared/Icons"
import { useSelector } from "react-redux"
import { useRegisterUserMutation } from "../../store/apiSlice"
import { useEffect, useState } from "react"
import { useNotify } from "../../utils/confirm"

const RegisterAdmin = () => {
	const username = useField()
	const email = useField()
	const emailConfirm = useField()
	const mode = useSelector((state) => state.layout.mode)
	const [registerUser, status] = useRegisterUserMutation()
	const [statusText, setStatusText] = useState(null)
	const fields = { username, email, emailConfirm }
	const [notify, Notification] = useNotify()

	useEffect(() => {
		if (status.isLoading) {
			setStatusText("Submitting")
		} else if (status.isSuccess) {
			setStatusText("User registered")
			Object.values(fields).forEach((field) => field.onReset())
		} else if (status.isError) {
			setStatusText(status.error.data.message)
		}
	}, [status])

	const handleRegisterUser = async () => {
		console.log(email.value + emailConfirm.value)
		if (emailConfirm.value === email.value){
			
			await registerUser({
				username: username.value,
				email: email.value,
				emailConfirm: emailConfirm.value,
			})
		}else{
			notify("Emails do not match!")
		}
		
	}

	return (
		<Box id="collection-view" className="scrollable-content" border={1}>
			<Notification/>
			<Box pl={"24px"} mt={1}>
				<Typography variant="h4">Register admin</Typography>
				<Box width={"500px"} border={1} pt={1} mt={2}>
					<Box height={"45px"} display={"flex"} paddingLeft={2}>
						<Box>
							<Title text="Username*" />
						</Box>
						<Box width={"70%"}> 
							<InputField field={username} isFullWidth />
						</Box>
					</Box>
					<Box height={"45px"} display={"flex"} borderTop={1} paddingLeft={2}>
					<Box>
							<Title text="Email*" />
						</Box>
						<Box width={"70%"}> 
							<InputField field={email} isFullWidth />
						</Box>
					</Box>
					<Box height={"45px"} display={"flex"} borderTop={1} borderBottom={1} paddingLeft={2}>
						<Box>
							<Title text="Confirm Email" />
						</Box>
						<Box width={"70%"}> 
							<InputField field={emailConfirm} isFullWidth />
						</Box>
					</Box>
					
					<Box display={"flex"} justifyContent={"space-between"}>
						<Box paddingLeft={"16px"} display={"flex"} alignItems={"center"}>
							<Box>{statusText}</Box>
						</Box>
						<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
							<Typography>REGISTER</Typography>

							<DropDownCloseIcon mode={mode} handleClick={handleRegisterUser} />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default RegisterAdmin
