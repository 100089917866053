import { Box } from "@mui/material"
import { useSelector } from "react-redux"

import { LightModeIcon, DarkModeIcon } from "../shared/Icons"

const DarkLightSelector = () => {
	const mode = useSelector((state) => state.layout.mode)

	return (
		<Box
			width={"33.3%"}
			textAlign={"center"}
			display={"flex"}
			justifyContent={"center"}
			borderRight={1}
		>
			<LightModeIcon mode={mode} />
			<DarkModeIcon mode={mode} />
		</Box>
	)
}

export default DarkLightSelector
