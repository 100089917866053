import { Box, Grid } from "@mui/material"
import TagItem from "../shared/TagItem"
import { useDispatch, useSelector } from "react-redux"
import {
	removeFilterTag,
	removeArtistTag,
	removePublisherTag,
} from "../../reducers/filterSlice"
import { forwardRef } from "react"
import useIsMobile from "../shared/useIsMobile"

const TagBox = forwardRef(( _ , ref) => {
	const filterTags = useSelector((state) => state.filter.query.filterTags)
	const artistTags = useSelector((state) => state.filter.query.artistTags)
	const publisherTags = useSelector((state) => state.filter.query.publisherTags)
	const dispatch = useDispatch()
	const removeTag = (text) => {
		dispatch(removeFilterTag(text))
	}
	const removeATag = (text) => {
		dispatch(removeArtistTag(text))
	}
	const removePTag = (text) => {
		dispatch(removePublisherTag(text))
	}

	const isMobile = useIsMobile()

	const hasTags = () =>{
		return filterTags.length > 0 || artistTags.length > 0 || publisherTags > 0 
	}

	const tagBoxStyle = {
			display: isMobile && !hasTags() ? "none" : "block",
			minHeight: isMobile ? "0px" : "300px",
			margin: "18px"
	  }
	
	return (
		<>
		<Box sx={tagBoxStyle}>
			<Grid ref={ref} container spacing={1}>
				{filterTags &&
					filterTags.map((item, index) => (
						<Grid key={index} item>
							<TagItem text={item} handleRemove={removeTag}></TagItem>
						</Grid>
					))}
				{artistTags &&
					artistTags.map((item, index) => (
						<Grid key={index} item>
							<TagItem text={item} handleRemove={removeATag}></TagItem>
						</Grid>
					))}
				{publisherTags &&
					publisherTags.map((item, index) => (
						<Grid key={index} item>
							<TagItem text={item} handleRemove={removePTag}></TagItem>
						</Grid>
					))}
			</Grid>
		</Box>
		<Box borderBottom={isMobile && hasTags() ? 1 : 0}></Box>
		</>
	)
})

TagBox.displayName = "TagBox"

export default TagBox
