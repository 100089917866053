import "./index.css"

import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { useMemo } from "react"
import MainView from "./components/MainView"
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom"

import { getDesignTokens } from "./theme"
import { useDispatch, useSelector } from "react-redux"
import DetailView from "./components/DetailView"
import { Box } from "@mui/material"
import SubmitView from "./components/SubmitView"

import NavBar from "./components/NavBar"

import LoginForm from "./components/LoginForm"
import { getUser } from "./utils/localStorage"
import { setCredentials } from "./reducers/authSlice"
import DashBoardView from "./components/DashboardView"
import ThankYou from "./components/submit/ThankYou"
import useIsMobile from "./components/shared/useIsMobile"
import AboutView from "./components/AboutView"
import ConsentBanner from "./ConsentBanner"
import PrivacyView from "./components/PrivacyView"
import MobileNavBar from "./components/MobileNavBar"
import ScrollToTop from "./components/shared/ScrollToTop"




function App() {
	const mode = useSelector((state) => state.layout.mode)

	const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])
	const isMobile = useIsMobile()
	const navigate = useNavigate()

	

	const handleLogoClick = () => {
		navigate("/")
		window.location.reload()
	}

	const mainTitle = "PUBLICATIONS"
	const submitTitle = "SUBMIT"
	const aboutTitle = "ABOUT"

	if (isMobile) {
		return (
			<>
				<ThemeProvider theme={theme}>
					<ScrollToTop />
					<ConsentBanner/>
					<CssBaseline />
					{/* The main container */}
					<Box
						display={"flex"}
						flexDirection={"column"}
						overflow={"hidden"}
						my={0}
						mx={"auto"}
						width={"100%"}
					>
						<MobileNavBar mainTitle={mainTitle} submitTitle={submitTitle} aboutTitle={aboutTitle} handleLogoClick={handleLogoClick} ></MobileNavBar>


						<Box display={"flex"} marginTop={"125px"} borderTop={1} >
							<Routes>
								<Route path="/thankyou" element={<ThankYou></ThankYou>}></Route>

								<Route path="/" element={<RequireAuth />} >
									<Route path="/" element={<MainView />} />
								</Route>

								<Route path="/login" element={<LoginForm></LoginForm>}></Route>
								<Route path="/:id" element={<RequireAuth />}>
									<Route path="/:id" element={<DetailView />} />
								</Route>
								<Route path="/submit" element={<RequireAuth />}>
									<Route path="/submit" element={<SubmitView />} />
								</Route>
								<Route path="/dashboard" element={<RequireAuth />}>
									<Route
										path="/dashboard"
										element={<DashBoardView view={"default"} />}
									/>
								</Route>
								<Route path="/dashboard/admins" element={<RequireAuth />}>
									<Route
										path="/dashboard/admins"
										element={<DashBoardView view={"admins"} />}
									/>
								</Route>
								<Route path="/about" element={<RequireAuth />}>
									<Route
										path="/about"
										element={<AboutView />}
									/>
								</Route>
								<Route
									path="/dashboard/register_admin"
									element={<RequireAuth />}
								>
									<Route
										path="/dashboard/register_admin"
										element={<DashBoardView view={"register_admin"} />}
									/>
								</Route>
							</Routes>
						</Box>
						{/* <Box
						border={1}
						display={"flex"}
						height={"420px"}
						width={"100%"}
					></Box> */}
					</Box>
				</ThemeProvider>
			</>
		)
	}

	return (
		<>
			<ThemeProvider theme={theme}>
			<ConsentBanner/>
				<CssBaseline />
				{/* The main container */}
				<Box
					display={"flex"}
					flexDirection={"column"}
					maxWidth={"2500px"}
					overflow={"hidden"}
					height={"100vh"}
					my={0}
					mx={"auto"}
				>
					<NavBar mainTitle={mainTitle} submitTitle={submitTitle} aboutTitle={aboutTitle} handleLogoClick={handleLogoClick} ></NavBar>

					<Box
						display={"flex"}
						sx={{ height: "calc(100vh - 180px)" }}
						width={"100%"}
					>
						<Routes>
							<Route path="/thankyou" element={<ThankYou></ThankYou>}></Route>

							<Route path="/" element={<RequireAuth />}>
								<Route path="/" element={<MainView />} />
							</Route>

							<Route path="/login" element={<LoginForm></LoginForm>}></Route>
							<Route path="/:id" element={<RequireAuth />}>
								<Route path="/:id" element={<DetailView />} />
							</Route>
							<Route path="/submit" element={<RequireAuth />}>
								<Route path="/submit" element={<SubmitView />} />
							</Route>
							<Route path="/dashboard" element={<RequireAuth />}>
								<Route
									path="/dashboard"
									element={<DashBoardView view={"default"} />}
								/>
							</Route>
							<Route path="/dashboard/admins" element={<RequireAuth />}>
								<Route
									path="/dashboard/admins"
									element={<DashBoardView view={"admins"} />}
								/>
							</Route>
							<Route path="/dashboard/register_admin" element={<RequireAuth />}>
								<Route
									path="/dashboard/register_admin"
									element={<DashBoardView view={"register_admin"} />}
								/>
							</Route>
							<Route path="/about" element={<RequireAuth />}>
									<Route
										path="/about"
										element={<AboutView />}
									/>
								</Route>
							
								<Route
									path="/privacy-policy"
									element={<PrivacyView />}
								/>
							
							
							
							
						</Routes>
					</Box>
					{/* <Box
						border={1}
						display={"flex"}
						height={"420px"}
						width={"100%"}
					></Box> */}
				</Box>
			</ThemeProvider>
		</>
	)
}

const RequireAuth = () => {
	let user = useSelector((state) => state.auth.user)
	const dispatch = useDispatch()
	if (!user) {
		user = getUser()

		dispatch(setCredentials({ user: user }))
	}

	const location = useLocation()

	return user ? (
		<Outlet />
	) : (
		<Navigate to="/login" state={{ from: location }} replace />
	)
}

export default App
