import { Box } from "@mui/material"
import InputDropDownRadioItem from "./InputDropDownRadioItem"

const InputDropDownRadio = ({ dropDownData, category }) => {
	return (
		<>
			<Box>
				{dropDownData &&
					Object.keys(dropDownData).map((key, index) => (
						<InputDropDownRadioItem
							key={index}
							data={dropDownData[key]}
							title={key}
                            category={category}
                           
						></InputDropDownRadioItem>
					))}
			</Box>
		</>
	)
}

export default InputDropDownRadio
