import { Box, Grid, Typography } from "@mui/material"

import { forwardRef } from "react"
import { useNavigate } from "react-router-dom"
import { truncated } from "../shared/truncateString"

const BookListItem = forwardRef(({ book }, ref) => {
	const navigate = useNavigate()

	return (
		<Grid className="book-list-item" item>
			<Box borderBottom={1} className="book-list-content">

			<Grid
          item
          xs={0.5}
		  borderRight={1}
          sx={{
            padding: "4px",
            
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%", // Ensure consistent height
            
          }}
        >
          <Box
		  	onClick={() => navigate(book.id)}
			className="clickable"
            component="img"
            src={book.thumbnail.url}
            alt="thumbnail"
            sx={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
              padding: "5px",
               // Ensure image padding is accounted for
            }}
          />
        </Grid>

				<Box
					borderRight={1}
					className="title clickable"
					onClick={() => navigate(book.id)}
				>
					<Typography
						sx={{ fontWeight: 530, ...truncated }}
						color="text.primary"
					>
						{book.title}
					</Typography>
				</Box>
				<Box borderRight={1} className="artist">
					<Typography
						sx={{
							fontWeight: "lighter",
							...truncated,
						}}
						color="text.secondary"
					>
						{book.artist}
					</Typography>
				</Box>
				<Box className="year" ref={ref}>
					<Typography
						sx={{
							fontWeight: "lighter",
						}}
						color="text.secondary"
					>
						{book.year}
					</Typography>
				</Box>
			</Box>
		</Grid>
	)
})
BookListItem.displayName = "BookListItem"
export default BookListItem
