import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	query: { search: "", page: 1, limit: 100, filterTags: [], filter: "new" },

	error: "",
	hasMorePages: false,
}

export const dashboardFilterSlice = createSlice({
	name: "¨dashboardfilter",
	initialState,
	reducers: {
		addFilterTag: (state, action) => {
			const tag = action.payload
			if (!state.query.filterTags.includes(tag)) {
				state.query.filterTags.push(tag)
			}
		},
		removeFilterTag: (state, action) => {
			const tag = action.payload
			state.query.filterTags = state.query.filterTags.filter((t) => t !== tag)
		},
		updateSearch: (state, action) => {
			state.query.search = action.payload
		},
		incrementPage: (state) => {
			state.query.page += 1
		},
		initSearch: (state) => {
			state.query.page = 1
		},
		addFilter: (state, action) => {
			state.query.filter = action.payload.toLowerCase()
		},
	},
})

export const {
	addFilterTag,
	removeFilterTag,
	updateSearch,
	incrementPage,
	initSearch,
	addFilter,
} = dashboardFilterSlice.actions

export default dashboardFilterSlice.reducer
