import { Box } from "@mui/material"

import DashUserItem from "./DashUserItem"
import { useGetUsersQuery } from "../../store/apiSlice"

const ManageAdmins = () => {
	const { data, isFetching } = useGetUsersQuery()
	return (
		<Box id="collection-view" className="scrollable-content" border={1}>
			{isFetching ? (
				<Box>Updating..</Box>
			) : (
				data?.users.map((user) => <DashUserItem key={user.id} user={user} />)
			)}
		</Box>
	)
}

export default ManageAdmins
