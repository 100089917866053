





export const submitDropDownPublisherData = {
	PUBLISHER: [
		"Self-Published"
	]
}



export const sortByDropdownData = {
	"SORT BY": ["Artist", "Year"],
}

export const dashboardFilterData = {
	Artbooks: ["All", "New", "Published", "Unpublished"],
}

export const dashboardUserData = {
	Manage: ["Admins", "Register admin"],
}

export const data = [...Array(100)]
