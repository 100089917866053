import { Box, Input } from "@mui/material"
import { useRef } from "react"
import { SearchIcon } from "../shared/Icons"
import { useDispatch, useSelector } from "react-redux"
import { initSearch, updateSearch } from "../../reducers/dashboardFilterSlice"

const DashBoardSearchBar = () => {
	const mode = useSelector((state) => state.layout.mode)
	const searchValue = useSelector((state) => state.dashboardFilter.query.search)

	const dispatch = useDispatch()

	const ref = useRef(null)

	const handleChange = (event) => {
		dispatch(initSearch())
		dispatch(updateSearch(event.target.value))
	}
	const handleIconClick = () => {
		ref.current.focus()
	}
	return (
		<>
			<Box display={"flex"} justifyContent={"center"} margin={"2px"}>
				<SearchIcon mode={mode} handleClick={handleIconClick} />
			</Box>
			<Box className="searchbar" borderRight={1}>
				<Input
					value={searchValue}
					onChange={(event) => handleChange(event)}
					inputRef={ref}
					disableUnderline
					fullWidth
					inputProps={{ spellCheck: "false" }}
					sx={{ height: "45px", fontSize: "24px", paddingLeft: 2 }}
				></Input>
			</Box>
		</>
	)
}

export default DashBoardSearchBar
