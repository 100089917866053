import { Box } from "@mui/material"

import LayoutBar from "./main-view/LayoutBar"
import CollectionView from "./main-view/CollectionVIew"
import SearchBar from "./main-view/SearchBar"

import { useDispatch, useSelector } from "react-redux"

import DropDownMenu from "./DropDownMenu"
import { sortByDropdownData } from "../data/dropdownData"
import {
	addArtistTag,
	addFilterTag,
	updateSort,
	addPublisherTag,
} from "../reducers/filterSlice"
import TagBox from "./main-view/TagBox"
import { useRef } from "react"
import { useGetArtistsQuery, useGetGenresQuery, useGetPublishersQuery, useGetThemesQuery } from "../store/apiSlice"
import useIsMobile from "./shared/useIsMobile"
import DarkLightSelector from "./main-view/DarkLightSelector"
import GridSizeSelector from "./main-view/GridSizeSelector"
import ScrollToTopButton from "./shared/ScrollToTopButton"
import ToggleAvailableToPurchaseSelection from "./main-view/ToggleAvailableToPurchaseSelection"

const MainView = () => {
	const { data: artistData, isSuccess } = useGetArtistsQuery()
	const { data: publisherData } = useGetPublishersQuery()
	const { data: themesData } = useGetThemesQuery()
	const { data: genresData } = useGetGenresQuery()
	
	
	const artistDropDownData = { ARTIST: isSuccess ? artistData : ["Loading.."] }
	const publisherDropDownData = {
		PUBLISHER: publisherData ? publisherData : ["Loading.."],
	}
	const dropDownData = {
		TYPE: genresData ? genresData : ["Loading.."],
		THEME: themesData ? themesData : ["Loading.."]
	}
	


	const gridSize = useSelector((state) => state.layout.gridSize)
	const gridView = useSelector((state) => state.layout.gridView)
	const dispatch = useDispatch()
	const ref = useRef(null)

	const handleSelectFilterTag = (text) => {
		dispatch(addFilterTag(text))
		scrollToLastElement()
	}

	const handleSelectArtistTag = (text) => {
		dispatch(addArtistTag(text))
		scrollToLastElement()
	}

	const handleSelectPublisherTag = (text) => {
		dispatch(addPublisherTag(text))
		scrollToLastElement()
	}

	const scrollToLastElement = () => {
		const tagBoxElement = ref.current
		tagBoxElement?.scrollIntoView({ behavior: "smooth" })
	}

	const handleSortBy = (text) => {
		dispatch(updateSort(text))
	}
	const isMobile = useIsMobile()
	const mobileGridSize = useSelector((state) => state.layout.mobileGridSize)

	if (isMobile) {
		return (
			<>
				<ScrollToTopButton />
				<Box display={"flex"} flexDirection={"column"} width={"100%"}>
					<Box display={"flex"} borderBottom={1}>
						<SearchBar isMobile={isMobile}></SearchBar>
						<DarkLightSelector />
						<GridSizeSelector />
					</Box>
					<TagBox ref={ref} />

					<CollectionView
						size={mobileGridSize}
						isGridView={true}
					></CollectionView>
				</Box>
			</>
		)
	}

	return (
		<>
			<Box
				display={"flex"}
				flexDirection={"column"}
				width={"25%"}
				position={"relative"}
				borderLeft={1}
				borderBottom={1}
			>
				<Box sx={{ overflow: "scroll", height: "calc(100vh - 180px)" }}>
					<DropDownMenu
						handleSelect={handleSelectArtistTag}
						dropDownData={artistDropDownData}
					/>
					<DropDownMenu
						handleSelect={handleSelectPublisherTag}
						dropDownData={publisherDropDownData}
					/>
					<DropDownMenu
						handleSelect={handleSelectFilterTag}
						dropDownData={dropDownData}
					/>
					
					<DropDownMenu
						handleSelect={handleSortBy}
						dropDownData={sortByDropdownData}
					/>
					<ToggleAvailableToPurchaseSelection> </ToggleAvailableToPurchaseSelection>
					<TagBox ref={ref} />
				</Box>
			</Box>

			<Box className="right-section">
				<Box className="fixed-nav" borderLeft={1} borderRight={1}>
					<SearchBar></SearchBar>
					<LayoutBar></LayoutBar>
				</Box>

				<CollectionView size={gridSize} isGridView={gridView}></CollectionView>
			</Box>
		</>
	)
}

export default MainView
