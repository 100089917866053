import { Box } from "@mui/material"
import { useSelector } from "react-redux"

import { PlusIcon, MinusIcon } from "../shared/Icons"

const GridSizeSelector = () => {
	const mode = useSelector((state) => state.layout.mode)

	return (
		<Box
			width={"33.3%"}
			textAlign={"center"}
			display={"flex"}
			justifyContent={"center"}
		>
			<PlusIcon mode={mode} />
			<MinusIcon mode={mode} />
		</Box>
	)
}

export default GridSizeSelector
