import { Box, Link } from "@mui/material"
import { NavLink } from "react-router-dom"
import { PbffLogo } from "./shared/Icons"
import { useSelector } from "react-redux"


const NavBar = ({mainTitle, submitTitle, aboutTitle, handleLogoClick}) => {
	const mode = useSelector((state) => state.layout.mode)
	const activeColor = mode === "light" ? "black" : "white"

	const navElementStyle = {
		color: "text.secondary",
		component: NavLink,
		underline: "none",

		sx: { "&.active": { color: activeColor }, fontSize: 22, fontWeight: 400, "&:hover": { opacity: 0.7  }  },
	}
	return (
		<Box
			width={"100%"}
			display={"flex"}
			
			border={1}
			borderTop={1}
			height={"180px"}
			
			
		>
			<Box
				width={"100%"}
				margin={"22px"}
				justifyContent={"space-between"}
				display={"flex"}
				height={"125px"}>
					
					
				<Box
					display={"flex"}
					justifyContent={"center"}
					onClick={() => handleLogoClick()}
					sx={{ cursor: "pointer" }}
					height={"125px"}
					
				>
					<PbffLogo mode={mode}></PbffLogo>
				</Box>
				<Box
					display={"flex"}
					
					width={"38%"}
					justifyContent={"space-between"}
					fontSize={"40px"}
					height={"50px"}
				>
					<Link padding={0} {...navElementStyle} to={"/"}>
						{mainTitle}
					</Link>
					<Link {...navElementStyle} to={"/submit/"}>
						{submitTitle}
					</Link>
					<Link {...navElementStyle} to={"/about"} component={NavLink}>
						{aboutTitle}
					</Link>
				</Box>
			</Box>
		</Box>
	)
}

export default NavBar
