import { useState } from "react"
import { Box, Link } from "@mui/material"
import { NavLink } from "react-router-dom"
import { HamburgerMenu, PbffLogo } from "./shared/Icons"
import { useSelector } from "react-redux"

const MobileNavBar = ({ mainTitle, submitTitle, aboutTitle, handleLogoClick }) => {
	const [menuOpen, setMenuOpen] = useState(false)
	const mode = useSelector((state) => state.layout.mode)
	const activeColor = mode === "light" ? "black" : "white"

    const navbarHeight = "125px"

	const toggleMenu = () => {
		setMenuOpen(!menuOpen)
	}

	const navElementStyle = {
		color: "text.secondary",
		component: NavLink,
		underline: "none",
		sx: {
			"&.active": { color: activeColor },
			fontSize: 34,
			fontWeight: 400,
			"&:hover": { opacity: 0.7 },
            margin: "12px"
		},
	}

	return (
		<Box display={"flex"}
        className={"sticky"}
        zIndex={1000}
        top={0}
        borderBottom={1}
           
         >
			{/* Navbar Container */}
			<Box
                 bgcolor={"background.default"}
				display={"flex"}
				justifyContent={"space-between"}
				width={"100%"}
				height={navbarHeight}
				padding={"12px"} // Adds padding to the navbar content
                
			>
				{/* Logo Section with Margin */}
				<Box
					onClick={handleLogoClick}
					display={"flex"}
					alignItems={"center"}
					height={"100%"}
				>
					<PbffLogo mode={mode} />
				</Box>

				{/* Hamburger Menu */}
				<Box
					onClick={toggleMenu}
                    className={"clickable"}
				>
					<HamburgerMenu isOpen={menuOpen} mode={mode}></HamburgerMenu> {/* Replace with an icon if needed */}
				</Box>
			</Box>

			{/* Fullscreen Menu Overlay */}
			{menuOpen && (
				<Box
					position={"fixed"}
					top={navbarHeight} // Starts after navbar
					left={0}
					width={"100%"}
					height={`calc(100vh - ${navbarHeight})`} // Excludes navbar height
					bgcolor={"background.default"}
					display={"flex"}
					flexDirection={"column"}
					alignItems={"center"}
					paddingTop={"175px"}
                    borderTop={1}
					zIndex={1100}
				>
					{/* Navigation Links */}
					<Link {...navElementStyle} to={"/"} onClick={toggleMenu}>
						{mainTitle}
					</Link>
					<Link {...navElementStyle} to={"/submit/"} onClick={toggleMenu}>
						{submitTitle}
					</Link>
					<Link {...navElementStyle} to={"/about"} onClick={toggleMenu}>
						{aboutTitle}
					</Link>
				</Box>
			)}
		</Box>
	)
}

export default MobileNavBar