import { createSlice } from "@reduxjs/toolkit"
import { removeUser } from "../utils/localStorage"

const initialState = {
	accessToken: null,
	user: null,
}

/* export const loginUser = createAsyncThunk(
	"login",
	async (creds, { rejectWithValue }) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
			},
			withCredentials: true,
		}
		try {
			const response = await axios.post(
				`${baseUrl}/api/user/login`,
				creds,
				config
			)
			return response.data
		} catch (error) {
			if (!error.response) {
				return rejectWithValue(error.message)
			}
			return rejectWithValue(error.response.data.message)
		}
	}
) */

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setCredentials: (state, action) => {
			const { accessToken, user } = action.payload

			if (accessToken) {
				state.accessToken = accessToken
			}
			if (user) {
				state.user = user
			}
		},
		removeCredentials: (state) => {
			state.accessToken = null
			state.user = null
			removeUser()
		},
	},
	/* extraReducers(builder) {
		builder
			.addCase(loginUser.pending, (state) => {
				state.loading = true
				state.error = null
			})
			.addCase(loginUser.fulfilled, (state, action) => {
				state.loading = false
				state.success = true
				state.accessToken = action.payload.accessToken
				state.refreshToken = action.payload.refreshToken
				state.user = action.payload.user
			})
			.addCase(loginUser.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
	}, */
})

export const { setCredentials, removeCredentials } = authSlice.actions

export default authSlice.reducer
