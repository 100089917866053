import  { useEffect, useState } from "react"

import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { colors } from "./theme"

const ConsentBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false)
  const [consentGiven, setConsentGiven] = useState(localStorage.getItem("analyticsConsent"))
  const mode = useSelector((state) => state.layout.mode)

  const bannerStyles = {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: mode == "dark" ? colors.white : colors.black,
    color: mode == "dark" ? colors.black : colors.white,
    padding: "20px",
    textAlign: "center",
    zIndex: 9999,
  }
  
  const buttonStyles = {
    backgroundColor: mode == "dark" ? colors.black : colors.white,
    color: mode == "dark" ? colors.white : colors.black ,
    padding: "10px 20px",
    margin: "10px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
  }
  
  const linkStyles = {
    color: mode == "dark" ? colors.black : colors.white,
    textDecoration: "underline",
  }


  // Show the banner if consent is not given
  useEffect(() => {
    if (!consentGiven) {
      setIsBannerVisible(true)
    } else {
      updateConsent(consentGiven)
    }
  }, [consentGiven])

  // Function to update consent in Google Analytics
  const updateConsent = (status) => {
    window.gtag("consent", "update", {
      "analytics_storage": status === "accepted" ? "granted" : "denied",
    })
  }
  console.log(isBannerVisible)
  // Handle accept button click
  const handleAccept = () => {
    localStorage.setItem("analyticsConsent", "accepted")
    setConsentGiven("accepted")
    setIsBannerVisible(false)
  }

  // Handle reject button click
  /* const handleReject = () => {
    localStorage.setItem("analyticsConsent", "denied")
    setConsentGiven("denied")
    setIsBannerVisible(false)
  } */

  return (
    <div>
      {/* Google Analytics Script */}
      

      {/* Consent Banner */}
      {isBannerVisible && (
        <div style={bannerStyles}>
          <p>
          We use cookies to analyze website traffic and improve your experience. By continuing to use this site, you consent to the use of analytics cookies. 
            <Link to="privacy-policy" style={linkStyles}>Learn more</Link>
          </p>

          {/*<button onClick={handleReject} style={{ ...buttonStyles, backgroundColor: "#f44336" }}>Reject</button>*/}
          <button onClick={handleAccept} style={buttonStyles}>OK</button>
        </div>
      )}
    </div>
  )
}

// Banner Styles


export default ConsentBanner