// store/loadingSlice.js
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isLoading: false, // Tracks whether any API call is in progress
  loadingCount: 0,  // Tracks the number of ongoing API calls
}

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loadingCount += 1
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.loadingCount -= 1
      state.isLoading = state.loadingCount > 0
    },
  },
})

export const { startLoading, stopLoading } = loadingSlice.actions
export const selectIsLoading = (state) => state.loading.isLoading

export default loadingSlice.reducer