import { Stack } from "@mui/material"

import {
	DeleteButton,
	PublishBookIcon,
	UnpublishBookIcon,
} from "../shared/Icons"
import { useConfirm } from "../../utils/confirm"
import {
	useDeleteBookMutation,
	useUpdateBookMutation,
} from "../../store/apiSlice"

const AdminButtons = ({ book }) => {
	const [confirm, Confirmation] = useConfirm()
	const [deleteBook] = useDeleteBookMutation()
	const [updateBook] = useUpdateBookMutation()

	const handleDelete = async () => {
		const confirmed = await confirm(
			`Are you sure you want to delete book: ${book.title}`
		)
		if (confirmed) {
			await deleteBook(book.id)
		}
	}

	const handleUnpublish = async () => {
		const confirmed = await confirm(
			`Are you sure you want unpublish ${book.title}`
		)
		if (confirmed) {
			updateBook({ id: book.id, update: { published: false } })
		}
	}

	const handlePublish = async () => {
		const confirmed = await confirm(
			`Are you sure you want publish ${book.title}`
		)
		if (confirmed) {
			updateBook({
				id: book.id,
				update: { published: true, new: false },
			})
		}
	}

	return (
		<>
			<Stack direction="row">
				<Confirmation />

				{book.published ? (
					<UnpublishBookIcon handleUnpublish={handleUnpublish} />
				) : (
					<>
						<PublishBookIcon handlePublish={handlePublish} />
					</>
				)}
				<DeleteButton handleDelete={handleDelete} />
			</Stack>
		</>
	)
}

export default AdminButtons
