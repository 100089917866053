import { Box } from "@mui/material"

const LeftSection = ({ component }) => {
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			width={"15%"}
			overflow={"scroll"}
		>
			{component}
		</Box>
	)
}

export default LeftSection
