import { Box, ImageList, ImageListItem } from "@mui/material"
import { useState, useCallback } from "react"
import ImageViewer from "react-simple-image-viewer"
import { useTheme } from "@mui/material/styles"

const MobileImageContainer = ({ book }) => {
	const [currentImage, setCurrentImage] = useState(0)
	const [isViewerOpen, setIsViewerOpen] = useState(false)
	const theme = useTheme()
	const [touchStart, setTouchStart] = useState(null)
	const [touchEnd, setTouchEnd] = useState(null)

	// the required distance between touchStart and touchEnd to be detected as a swipe
	const minSwipeDistance = 50 

	const onTouchStart = (e) => {
	setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
	setTouchStart(e.targetTouches[0].clientX)
	}

	const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return
		const distance = touchStart - touchEnd
		const isLeftSwipe = distance > minSwipeDistance
		const isRightSwipe = distance < -minSwipeDistance
		if (isLeftSwipe){
			setCurrentImage((prevIndex) => (prevIndex + 1) % book.images.length)
		}
		if (isRightSwipe){
			setCurrentImage((prevIndex) => (prevIndex - 1 + book.images.length) % book.images.length)
		}

	}

	const viewerBackgroundStyle = {
		backgroundColor: theme.palette.background.default,
	}

	const openImageViewer = useCallback((index) => {
		setCurrentImage(index)
		setIsViewerOpen(true)
	}, [])

	const closeImageViewer = () => {
		setCurrentImage(0)
		setIsViewerOpen(false)
	}
	const leftArrow = (
		<div style={{ color: theme.palette.primary.main }}>{"<"}</div>
	)
	const rightArrow = (
		<div style={{ color: theme.palette.primary.main }}>{">"}</div>
	)
	const closeIcon = <div style={{ color: theme.palette.primary.main }}>x</div>



	return (
		<>
			<Box borderBottom={1} minHeight={"300px"}>
				<ImageList cols={1} sx={{ width: "100%", margin: "0"}}>
					{book && (
						<ImageListItem onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
							onClick={() => openImageViewer(currentImage)}
							key={book.images[currentImage].id}
						>
							<img src={book.images[currentImage].url}></img>
						</ImageListItem>
					)}
				</ImageList>
			</Box>

			{isViewerOpen && (
				<Box zIndex={1200}>
					<ImageViewer
						leftArrowComponent={leftArrow}
						rightArrowComponent={rightArrow}
						backgroundStyle={viewerBackgroundStyle}
						src={book.images?.map((img) => img.url)}
						currentIndex={currentImage}
						disableScroll={true}
						closeOnClickOutside={true}
						onClose={closeImageViewer}
						closeComponent={closeIcon}
						
					/>
				</Box>
			)}
		</>
	)
}

export default MobileImageContainer
