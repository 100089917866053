import { Box, Typography } from "@mui/material"
import { DimensionValue, Title, TitleValue } from "./Components"
import { DarkModeIcon, LightModeIcon } from "../shared/Icons"
import { useSelector } from "react-redux"
import CategoryTagBox from "./CategoryTagBox"
import SecondaryDetail from "./SecondaryDetail"
import useIsMobile from "../shared/useIsMobile"

const DetailContainer = ({ book }) => {
	const mode = useSelector((state) => state.layout.mode)
	const itemStyle = {
		height: "45px",
		display: "flex",
		alignItems: "center",
	}

	const extraCategories = [
		{ "Illustrator/s": book?.illustrator ?? "" },
		{ "Graphic designer/s": book?.graphicDesigner ?? "" },
		{ "Photographer/s": book?.photographer ?? "" },
		{ "Writer/s": book?.writers ?? "" },
		{ "Editor/s": book?.editors ?? "" },
		{ "Proofreading": book?.proofreading ?? "" },
		{ "Language/s": book?.language ?? "" },
		{ "Translation/s": book?.translation ?? "" },
		{ "Repro": book?.repro ?? "" },
		{ "Printing technique": book?.printingTechnique ?? "" },
		{ "Binding technique": book?.bindingTechnique ?? "" },
		{ "Paper/s": book?.paper ?? "" },
		{ "Typeface/s": book?.typeface ?? "" },
		{ "Printing house": book?.printingHouse ?? "" },
		{ "Bindery": book?.bindery ?? "" },
		{ "Other information": book?.otherInformation ?? "" },
		{ "ISBN": book?.isbn ?? "" }
	  ]
	

	const details = extraCategories
		.filter(category => {
			const title = Object.keys(category)[0]
			const value = category[title]
			return value !== null && value !== undefined
		})
		.map(category => {

			const title = Object.keys(category)[0]
			const value = category[title]
			return <SecondaryDetail key={title} title={title} value={value} />
		})
	const isMobile = useIsMobile()

	if (book) {
		return (
			<Box
			 
				overflow={isMobile ? "" : "scroll"}
				height={isMobile ? "auto" : "calc(100vh - 180px)"}
			>
				<Box sx={itemStyle} paddingLeft={2}>
					<Title text={"TITLE"}></Title>
					<TitleValue text={book.title}></TitleValue>
				</Box>
				<Box sx={itemStyle} borderTop={1} paddingLeft={2}>
					<Title text={"ARTIST/S"}></Title>
					<TitleValue text={book.artist}></TitleValue>
				</Box>
				{isMobile ? (
					<>
						<Box sx={itemStyle} borderTop={1} paddingLeft={2}>
							<Box sx={itemStyle} width={"35%"} height={"45px"} borderRight={1} >
								<Title text={"YEAR"}></Title>
								<TitleValue text={book.year}></TitleValue>
							</Box>
							<Box
								sx={itemStyle}
								width={"55%"}
								height={"45px"}
								paddingX={2}
							>
								<Title text={"DIMENSIONS"}></Title>
								<Box width={"100%"} display={"flex"}>
									<Box paddingRight={"0.5rem"}>
										<DimensionValue text={book.dimensionX}></DimensionValue>
									</Box>
									<Box paddingRight={"0.5rem"}>
										<Typography variant="medium18" fontWeight={530}>
											x
										</Typography>
									</Box>
									<Box paddingRight={"0.5rem"}>
										<DimensionValue text={book.dimensionY} />
									</Box>
									<Box paddingRight={"0.5rem"}>
										<Typography variant="medium18" fontWeight={530}>
											cm
										</Typography>
									</Box>
								</Box>
							</Box>
							
							
						</Box>
						<Box sx={itemStyle} borderTop={1} paddingLeft={2}>
							
							<Box sx={itemStyle} width={"35%"} height={"45px"} borderRight={1}>
								<Title text={"EDITION"}></Title>
								<TitleValue text={book.edition}></TitleValue>
							</Box>
							<Box sx={itemStyle} width={"40%"} paddingLeft={2} borderRight={1}>
								<Title text={"PAGES"}></Title>
								<TitleValue text={book.pageCount}></TitleValue>
							</Box>

							<Box
								sx={itemStyle}
								width={"25%"}
								height={"45px"}
								justifyContent={"center"}
							>
								<LightModeIcon mode={mode} />
								<DarkModeIcon mode={mode} />
							</Box>
						</Box>
					</>
				) : (
					<>
						<Box sx={itemStyle} borderTop={1} paddingLeft={2}>
						<Box
								sx={itemStyle}
								width={"45%"}
								height={"45px"}
								borderRight={1}
								
							>
								<Title text={"YEAR"}></Title>
								<TitleValue text={book.year}></TitleValue>
							</Box>
							<Box
								sx={itemStyle}
								width={"45%"}
								height={"45px"}
								marginLeft={2}
							>
								<Title text={"DIMENSIONS"}></Title>
								<Box width={"100%"} display={"flex"}>
									<Box paddingRight={"0.5rem"}>
										<DimensionValue text={book.dimensionX}></DimensionValue>
									</Box>
									<Box paddingRight={"0.5rem"}>
										<Typography variant="medium18" fontWeight={530}>
											x
										</Typography>
									</Box>
									<Box paddingRight={"0.5rem"}>
										<DimensionValue text={book.dimensionY} />
									</Box>
									<Box paddingRight={"0.5rem"}>
										<Typography variant="medium18" fontWeight={530}>
											cm
										</Typography>
									</Box>
								</Box>
								
								
							</Box>
							<Box
								sx={itemStyle}
								width={"10%"}
								justifyContent={"center"}
								
								
							>
							</Box>
							
						</Box>
						<Box sx={itemStyle} borderTop={1} paddingLeft={2}>
							<Box sx={itemStyle} width={"45%"} height={"45px"} borderRight={1}>
								<Title text={"PAGE COUNT"}></Title>
								<TitleValue text={book.pageCount}></TitleValue>
							</Box>
							<Box sx={itemStyle} width={"35%"} borderRight={1} marginLeft={2}>
								<Title text={"EDITION"}></Title>
								<TitleValue text={book.edition}></TitleValue>
							</Box>
							<Box
								sx={itemStyle}
								width={"20%"}
								justifyContent={"center"}
								
								
							>
								<Box >
									<LightModeIcon mode={mode} />
									<DarkModeIcon mode={mode} />
								</Box>
							</Box>
							
						</Box>
					</>
				)}

				<Box
					display={"flex"}
					flexDirection={"column"}
					paddingTop={1}
					borderTop={1}
					height={"auto"}
					paddingLeft={2}
					paddingRight={2}
					paddingBottom={2}
					borderBottom={1}
				>
					<Title text={"DESCRIPTION"}></Title>
					<Typography
						whiteSpace={"pre-line"}
						paddingRight={2}
						variant="p"
						fontWeight={530}
					>
						{book.description}
					</Typography>
				</Box>
				<Box display={"flex"}
					flexDirection={"column"}
					paddingTop={1}
					borderTop={1}
					height={"auto"}
					paddingLeft={2}
					paddingRight={2}
					paddingBottom={2}
					borderBottom={1}>
					

					<SecondaryDetail title={"Publisher"} value={book.publisher} />
					{details}

					<SecondaryDetail clickType={"url"} title={"Artist/s website"} value={book.artistWebsite}/>
					{book.availableToPurchase === true ? <SecondaryDetail
													clickType={"email"}
													title={isMobile ? "Contact information" : "Contact information for sale"} 
													value={book.contactInformation}/> 
													: null}
				</Box>
					
				<Box borderBottom={1}></Box>
				<CategoryTagBox categoryTags={book.categoryTags} />
				
			</Box>
		)
	}
}

export default DetailContainer
