import { Box } from "@mui/material"
import DropDownItem from "./DropDownItem"

const DropDownMenu = ({ dropDownData, handleSelect }) => {
	return (
		<>
			<Box>
				{dropDownData &&
					Object.keys(dropDownData).map((key, index) => (
						<DropDownItem
							key={index}
							data={dropDownData[key]}
							title={key}
							handleSelect={handleSelect}
						></DropDownItem>
					))}
			</Box>
		</>
	)
}

export default DropDownMenu
