export const truncateString = (inputString, mainThreshold, wordThreshold) => {
	const words = inputString.split(" ")

	let builder = []
	for (const word of words) {
		builder.push(word)
		if (word.length > wordThreshold) {
			mainThreshold = wordThreshold
			break
		}
	}

	const result = builder.join(" ")

	if (result.length > mainThreshold) {
		return result.substring(0, mainThreshold)
	} else {
		return result
	}
}

export const truncated = {
	textOverflow: "ellipsis",
	overflow: "hidden",
	display: "-webkit-box",
	WebkitLineClamp: "1",
	WebkitBoxOrient: "vertical",
	msWordBreak: "break-all",
	wordBreak: "break-all",
	overflowWrap: "break-word", // Fallback for non-IE browsers
}
