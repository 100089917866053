import { Box } from "@mui/material"
import { BoldTitle } from "../book-details/Components"
import { PurchaseRadioButton } from "../shared/Icons"

const AvailableToPurchaseInput = ({ title, itemStyle, mode }) => {
    return (
        <Box sx={itemStyle} borderTop={1} paddingLeft={2} >
            <BoldTitle text={title} />
            <PurchaseRadioButton mode={mode} size={14}></PurchaseRadioButton>
        </Box>
    )
}

export default AvailableToPurchaseInput
