import { Box } from "@mui/material"
import { BoldTitle, InputField } from "../book-details/Components"

const BoldTextInput = ({ title, field, itemStyle, placeholder }) => {
    return (
        <Box sx={itemStyle} borderTop={1} paddingLeft={2}>
            <BoldTitle text={title} />
            <InputField isFullWidth={true} field={field} placeholder={placeholder} />
        </Box>
    )
}

export default BoldTextInput
