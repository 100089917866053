import { Box, Grid, Typography } from "@mui/material"
import useIsMobile from "../shared/useIsMobile"
import { useDispatch } from "react-redux"
import { addFilterTag, initSearch } from "../../reducers/filterSlice"
import { useNavigate } from "react-router-dom"

const CategoryTagBox = ({ categoryTags }) => {
	const isMobile = useIsMobile()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	
	const handleCategoryTagClick = (category) =>{
		dispatch(initSearch())
		dispatch(addFilterTag(category))
		navigate("/")

	}



	if (categoryTags && categoryTags[0] !== "") {
		return (
			<Box minHeight={isMobile ? "" : "300px"} margin={"18px"}>
				<Grid container spacing={1}>
					{categoryTags &&
						categoryTags.map((item, index) => (
							<Grid key={index} item>
								<Box
									padding={"8px"}
									border={2}
									display={"flex"}
									alignItems={"center"}
									width={"fit-content"}
									whiteSpace={"nowrap"}
									onClick={() => handleCategoryTagClick(item)}
									sx = {{
										"&:hover": {
          									cursor: "pointer",}}}
								>
									<Typography>{item}</Typography>
								</Box>
							</Grid>
						))}
				</Grid>
			</Box>
		)
	} else {
		return null
	}
}

export default CategoryTagBox
