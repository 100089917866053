import { Box, Input, Typography, Link } from "@mui/material"
import { useState } from "react"
import { truncated } from "../shared/truncateString"


export const Title = ({ text }) => {
	return (
		<Typography marginRight={2} variant="submitTitle">
			{text}
		</Typography>
	)
}

export const OneLineTitle = ({ text }) => {
	return (
		<Typography whiteSpace={"nowrap"} marginRight={2} variant="submitTitle">
			{text}
		</Typography>
	)
}

export const BoldTitle = ({ text }) => {
	return (
		<Typography
			whiteSpace={"nowrap"}
			marginRight={2}
			variant="submitTitle"
			
		>
			{text}
		</Typography>
	)
}

export const MobileBoldTitle = ({ text }) => {
	return (
		<Typography
			whiteSpace={"nowrap"}
			marginRight={2}
			variant="thin18"
			fontWeight={530}
		>
			{text}
		</Typography>
	)
}

export const DimensionValue = ({ text }) => {
	return (
		<Typography variant="medium18" fontWeight={530}>
			{text}
		</Typography>
	)
}

export const TitleValue = ({ text, notTruncated }) => {
	let style = {}
	if (notTruncated) {
		style = {
			marginRight: 2,
		}
	} else {
		style = {
			...truncated,
			marginRight: 2,
		}
	}
	return (
		<Typography sx={style} variant="medium18" fontWeight={530}>
			{text}
		</Typography>
	)
}

export const TitleValue2 = ({ text, clickType }) => {
	if (clickType == "url"){
		const url = ensureHttp(text)
		return <Typography className="clickable"  variant="medium18"> <Link href={url} target="_blank" rel="noopener noreferrer">{text}</Link></Typography>
	}else if (clickType == "email") {
		const link = `mailto:${text}`
		return <Typography className="clickable"  variant="medium18"> <Link href={link}> {text} </Link> </Typography>
	}
	return <Typography marginTop={"3px"} variant="medium18">{text}</Typography>
}


export const InputField = ({
	isMultiline,
	isFullWidth,
	type,
	field,
	placeholder,
	fontWeight,
}) => {
	return (
		<Input
			required
			multiline={isMultiline}
			placeholder={placeholder}
			spellCheck={false}
			{...field}
			type={type}
			fullWidth={isFullWidth}
			disableUnderline
			
			sx={{ fontWeight: fontWeight, cursor: "text", fontSize: "20px" }}
		></Input>
	)
}

export const Text = () => {
	return (
		<Box padding={"12px"}>
			<Typography variant="p" margin={"18px"}>
				It is said that a photograph can stop time. With the help of a camera, a
				slice of time can be preserved on a light sensitive surface. To arrest
				the elusive nature of light, was the intent of young Finnish artist
				Maija Annikki Savolainen. “When the Sense of Belonging is Bound to a
				System of Movement” tells a story of a ten day travel that she did in
				order to reinterpret the mechanical aspect of photography. Maija Annikki
				Savolainen defines the book being a travel diary of a road trip through
				time. The book presents a lucid concept and a compact project, combining
				factual research of light as hand drawn diagrams with »down to earth«
				photography. The photography itself, the travel documentation of various
				places and people, are observations and notions and are presented in the
				book as film strips. The combination of these elements unfolds the story
				to a poetic level along with the layout, directing the reader with a
				gentle sense of humour. While the author and the creator of the book is
				traveling and following the sun to keep the day the exact same length,
				she is not only exploring questions of existence but alsobringing
				forward what is essential in photography, the light. Maija Annikki
				Savolainen (b. 1981) is winner of the Nordic Dummy
			</Typography>
		</Box>
	)
}

export const useField = () => {
	const [value, setValue] = useState("")

	const onChange = (event) => {
		setValue(event.target.value)
	}

	const onReset = () => setValue("")

	

	return {
		value,
		onChange,
		onReset,
		
	}
}


const ensureHttp = (url) => {
	// Check if the URL starts with "http" or "https"
	if (url.startsWith("http://") || url.startsWith("https://")) {
	  return url
	}
	// If not, prepend "http://"
	return `http://${url}`
  }
