import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollToTop = () => {
  const location = useLocation() // Get the current location (URL)

  useEffect(() => {
    // Scroll to the top every time the route changes
    window.scrollTo(0, 0)
  }, [location]) // Dependency on location will trigger this effect on route change

  return null
}

export default ScrollToTop