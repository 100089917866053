import { Box, Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { DeleteButton } from "../shared/Icons"
import { useDeleteUserMutation } from "../../store/apiSlice"
import { useConfirm, useNotify } from "../../utils/confirm"
import { useSelector } from "react-redux"

const DashUserItem = ({ user }) => {
	const auth = useSelector((state) => state.auth)

	const navigate = useNavigate()
	const [confirm, Confirmation] = useConfirm()
	const [deleteUser] = useDeleteUserMutation()
	const [notify, Notification] = useNotify()

	const handleDeleteUser = async () => {
		if (auth.user.role !== "master") {
			await notify("Invalid permissions")
		} else if (user.role === "master") {
			await notify("Master user cannot be deleted")
		} else {
			const confirmed = await confirm(
				`Are you sure you want to delete user ${user.username}`
			)
			if (confirmed) {
				await deleteUser({
					username: user.username,
					email: user.email,
					role: user.role,
				})
			}
		}
	}

	return (
		<Grid borderBottom={1} className="book-list-item" item>
			<Confirmation />
			<Notification />
			<Box className="book-list-content">
				<Box
					borderRight={1}
					className="title"
					onClick={() => navigate(`/${user.id}`)}
				>
					<Typography sx={{ fontWeight: "bold" }} color="text.primary">
						{user.username}
					</Typography>
				</Box>
				<Box
					borderRight={1}
					paddingLeft={"1%"}
					display={"flex"}
					alignItems={"center"}
					width={"30%"}
					height={"100%"}
				>
					<Typography
						sx={{
							fontWeight: "lighter",
						}}
						color="text.secondary"
					>
						{user.email}
					</Typography>
				</Box>
				<Box
					borderRight={1}
					paddingLeft={"1%"}
					display={"flex"}
					alignItems={"center"}
					width={"15%"}
					height={"100%"}
				>
					<Typography
						sx={{
							fontWeight: "lighter",
						}}
						color="text.secondary"
					>
						{user.role}
					</Typography>
				</Box>
				<Box
					borderRight={1}
					paddingLeft={"1%"}
					display={"flex"}
					alignItems={"center"}
					width={"15%"}
					height={"100%"}
					justifyContent={"center"}
				>
					<DeleteButton handleDelete={handleDeleteUser} />
				</Box>
			</Box>
		</Grid>
	)
}

export default DashUserItem
