import { Box } from "@mui/material"
import { BoldTitle, MobileBoldTitle,  TitleValue2 } from "./Components"
import useIsMobile from "../shared/useIsMobile"

const SecondaryDetail = ({ title, value, clickType }) => {

	const isMobile = useIsMobile()

	const itemStyle = {
		
		
		display: value == "" || !value ? "none" : "flex",
		
	}

	if (isMobile){
		return (
			<Box sx={itemStyle} >
				<Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"}>
					<MobileBoldTitle text={title} />
				</Box>
				<Box marginTop={"1px"}>
					<TitleValue2 clickType={clickType} text={value} />
				</Box>
			</Box>
		)
	}else{
		return (
			<Box sx={itemStyle} >
				<Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"}>
					<BoldTitle text={title} />
				</Box>
				<Box marginTop={"4px"}>
					<TitleValue2 clickType={clickType} text={value} />
				</Box>
			</Box>
		)
	}
		
}

export default SecondaryDetail
