import { Box, Typography } from "@mui/material"
import { BackButton } from "../shared/Icons"
import { useSelector } from "react-redux"
import useIsMobile from "../shared/useIsMobile"

const ThankYou = () => {
	const mode = useSelector((state) => state.layout.mode)
	const isMobile = useIsMobile()

	if (isMobile) {
		return (
			<Box display={"flex"} width={"100%"} justifyContent={"center"}>
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					margin={6}
					marginLeft={30}
					marginRight={30}
					textAlign={"center"}
				>
					<Typography marginBottom={8} variant="h4">
						Thank you for your submission!
					</Typography>
					<BackButton mode={mode} size={40}></BackButton>
				</Box>
			</Box>
		)
	}

	return (
		<Box display={"flex"} width={"100%"} justifyContent={"center"}>
			<Box
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"center"}
				alignItems={"center"}
				margin={12}
				marginLeft={30}
				marginRight={30}
				textAlign={"center"}
			>
				<Typography marginBottom={4} variant="h1">
					Thank you for your submission!
				</Typography>
				<BackButton mode={mode} size={40}></BackButton>
			</Box>
		</Box>
	)
}

export default ThankYou
