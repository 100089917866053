import { Box } from "@mui/material"

import InputDropDownRadio from "../InputDropDownRadio"
import { useGetGenresQuery, useGetPublishersQuery, useGetThemesQuery } from "../../store/apiSlice"

const SubmitDropDown = () => {

	const { data: publisherData } = useGetPublishersQuery()
	const { data: themeData } = useGetThemesQuery()
	const { data: genreData } = useGetGenresQuery()

	/* const moveSelfPublishedToFirst = (arr) => {
		const item  = "Self-Published"
		const index = arr.indexOf(item)
		if (index !== -1) {  // Check if the item exists in the array
			arr.splice(index, 1)  // Remove the item from its current position
			arr.unshift(item)  // Insert the item at the first position
		}
		return arr
	} */
	const publisherDropDownData = {
		PUBLISHER: publisherData ?  publisherData : ["Loading.."],
	}
	const themeDropDownData = {
		THEME: themeData ? themeData : ["Loading.."],
	}
	const genreDropDownData = {
		TYPE: genreData ?  genreData: ["Loading.."],
	}


	return (
		<>
			<Box borderTop={1} display={"flex"}>
				<Box borderRight={1} width={"50%"}>
					<Box borderBottom={1}>
						<InputDropDownRadio dropDownData={themeDropDownData} category={"theme"}  />
					</Box>
					<Box>
						<InputDropDownRadio dropDownData={publisherDropDownData} category={"publisher"} />
					</Box>
					
				</Box>
				<Box width={"50%"}>
					
					<Box>
						<InputDropDownRadio dropDownData={genreDropDownData} category={"genre"}   />
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default SubmitDropDown
