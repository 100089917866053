import { apiSlice } from "./apiSlice"

export const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (creds) => ({
				url: "/user/login",
				method: "POST",
				body: creds,
			}),
		}),
		logout: builder.mutation({
			query: () => ({
				url: "/user/logout",
				method: "POST",
			}),
		}),
	}),
})

export const { useLoginMutation, useLogoutMutation } = authApiSlice
