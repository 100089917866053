import { Box, Typography } from "@mui/material"
import { ToggleAvailableToPurchaseButton } from "../shared/Icons"
import { useSelector } from "react-redux"
import { truncated } from "../shared/truncateString"

const ToggleAvailableToPurchaseSelection = () => {
    const mode = useSelector((state) => state.layout.mode)
    
    
    return (
        <Box className={"dropdown-item"} borderBottom={1} width={"100%"}>
            <Box
                height={"45px"}
                alignItems={"center"}
                display={"flex"}
                justifyContent={"space-between"}
             
                        
                    >
                        <Typography sx={{
									
									...truncated,
								}} paddingLeft={"18px"} variant="categoryTitle">
                            {"AVAILABLE TO PURCHASE"}
                            
                        </Typography>
                        <Box margin={"4px"} marginRight={"5.5px"} display={"flex"} justifyContent={"center"} >
                            <ToggleAvailableToPurchaseButton mode={mode} size={19}></ToggleAvailableToPurchaseButton>
                        </Box>


            
            </Box>
        </Box>
    )
}

export default ToggleAvailableToPurchaseSelection
