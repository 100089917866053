import { Box } from "@mui/material"
const MiddleSection = ({ component }) => {
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			width={"38%"}
			borderRight={1}
			overflow={"scroll"}
		>
			{component}
		</Box>
	)
}

export default MiddleSection
