import { Box, ListItem, Typography, List, Slide, Input } from "@mui/material"
import { useRef, useState } from "react"
import {
	DropDownCloseIcon,
	DropDownOpenIcon,
	InputRadioButton,
	RadioButton,
} from "./shared/Icons"
import { useSelector } from "react-redux"

const InputDropDownRadioItem = ({ data, title, category }) => {
	const [isOpen, setIsOpen] = useState(false)
	const mode = useSelector((state) => state.layout.mode)
	const containerRef = useRef(null)
	const [textInput, setTextInput] = useState("")
	
	
	const placeholderValue = `Type ${title.toLowerCase()} here`

	const handleClick = () => {
		setIsOpen(!isOpen)
	}

	return (
		<>
			<Box className={"dropdown-item"} width={"100%"} minHeight={"45px"}>

				<Box
					height={"45px"}
					alignItems={"center"}
					display={"flex"}
					justifyContent={"space-between"}
					onClick={() => handleClick()}
				>
					<Typography paddingLeft={"18px"} variant="submitTitle">
						{title}
					</Typography>
					{isOpen ? (
						<DropDownCloseIcon mode={mode} handleClick={handleClick} />
					) : (
						<DropDownOpenIcon mode={mode} handleClick={handleClick} />
					)}
				</Box>

				<Box ref={containerRef}>
					{isOpen ? (
						<List>
							<>
							{data.map((item, index) => (
								<>
									<ListItem key={index} disablePadding>
										<Box display={"flex"} marginLeft={"11px"}>
											<Box>
											<RadioButton
												mode={mode}
												size={12}
												item={item}
												category={category}
											></RadioButton>
											</Box>
											<Box>
												<Slide
													direction="down"
													in={isOpen}
													container={containerRef.current}
													mountOnEnter
													unmountOnExit
												>
													<Typography
														paddingBottom={"4px"}
														
														variant="thin16"
													>
														{item}
													</Typography>
												</Slide>
											</Box>
										</Box>
									</ListItem>
								</>
							))}
							</>

							<ListItem disablePadding>
								<Box marginLeft={"11px"} display={"flex"}>
									<InputRadioButton mode={mode} size={12} textInput={textInput} category={category}></InputRadioButton>

									<Box >
										<Input
											paddingLeft={"2px"}
											variant="thin16"
											onChange={(e) => setTextInput(e.target.value)}
											placeholder={placeholderValue}
											disableUnderline
											spellCheck={false}
										>
											{textInput}
										</Input>
									</Box>
								</Box>
							</ListItem>
						</List>
					) : null}
				</Box>
			</Box>
		</>
	)
}
export default InputDropDownRadioItem
