import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	query: {
		search: "",
		page: 1,
		limit: 6,
		filterTags: [],
		artistTags: [],
		publisherTags: [],
		sortBy: "year",
		sortType: -1,
		onlyAvailableToPurchase: false
	},

	error: "",
	hasMorePages: false,
}

export const filterSlice = createSlice({
	name: "filter",
	initialState,
	reducers: {
		addFilterTag: (state, action) => {
			const tag = action.payload
			if (!state.query.filterTags.includes(tag)) {
				state.query.filterTags.push(tag)
			}
		},
		removeFilterTag: (state, action) => {
			const tag = action.payload
			state.query.filterTags = state.query.filterTags.filter((t) => t !== tag)
		},
		addArtistTag: (state, action) => {
			const tag = action.payload
			if (!state.query.artistTags.includes(tag)) {
				state.query.artistTags.push(tag)
			}
		},
		removeArtistTag: (state, action) => {
			const tag = action.payload
			state.query.artistTags = state.query.artistTags.filter((t) => t !== tag)
		},

		addPublisherTag: (state, action) => {
			const tag = action.payload
			if (!state.query.publisherTags.includes(tag)) {
				state.query.publisherTags.push(tag)
			}
		},
		removePublisherTag: (state, action) => {
			const tag = action.payload
			state.query.publisherTags = state.query.publisherTags.filter(
				(t) => t !== tag
			)
		},
		updateSearch: (state, action) => {
			state.query.search = action.payload
		},
		incrementPage: (state) => {
			state.query.page += 1
		},
		initSearch: (state) => {
			state.query = initialState.query
		},
		updateSort: (state, action) => {
			const sortUpdate = action.payload.toLowerCase()

			state.query.sortType = state.query.sortType * -1
			state.query.sortBy = sortUpdate
		},
		toggleAvailableToPurchase: (state, action) => {
			state.query.onlyAvailableToPurchase = action.payload
		}
	},
})

export const {
	addFilterTag,
	removeFilterTag,
	addArtistTag,
	removeArtistTag,
	addPublisherTag,
	removePublisherTag,
	updateSearch,
	incrementPage,
	initSearch,
	updateSort,
	toggleAvailableToPurchase,
} = filterSlice.actions

export default filterSlice.reducer
