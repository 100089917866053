import { useDispatch } from "react-redux"
import { Box, Button, TextField, Typography } from "@mui/material"

import { useField } from "./book-details/Components"
import { useLoginMutation } from "../store/authApiSlice"
import { setCredentials } from "../reducers/authSlice"
import { setUser } from "../utils/localStorage"
import { useNavigate } from "react-router-dom"

const LoginForm = () => {
	const username = useField()
	const password = useField()
	const [login, { isLoading, isError, error }] = useLoginMutation()
	const errorMsg = isError ? error?.message : ""
	const dispatch = useDispatch()
	const from = location.state?.from?.pathname || "/"
	const navigate = useNavigate()
	const handleLogin = async (event) => {
		event.preventDefault()
		const creds = { username: username.value, password: password.value }
		const payload = await login(creds).unwrap()

		if (payload?.user) {
			dispatch(setCredentials(payload))
			setUser(JSON.stringify(payload.user))

			navigate(from, { replace: true })
		}
	}

	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			width={"100%"}
			component="main"
			maxWidth="xs"
		>
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<Typography>{errorMsg}</Typography>
				<Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
					<TextField
						disabled={isLoading}
						{...username}
						margin="normal"
						required
						fullWidth
						id="username"
						label="username"
						name="username"
						autoComplete="username"
						autoFocus
					/>
					<TextField
						disabled={isLoading}
						{...password}
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
					/>

					<Button
						disabled={isLoading}
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						Sign In
					</Button>
				</Box>
			</Box>
		</Box>
	)
}

export default LoginForm
