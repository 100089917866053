import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ScrollToTopIcon } from "./Icons"

const ScrollToTopButton = () => {
	const mode = useSelector((state) => state.layout.mode)
	const [showScrollButton, setShowScrollButton] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			// Check if the user has scrolled down enough to show the button
			if (window.scrollY > window.innerHeight / 2) {
				setShowScrollButton(true)
			} else {
				setShowScrollButton(false)
			}
		}

		// Add scroll event listener when component mounts
		window.addEventListener("scroll", handleScroll)

		// Remove scroll event listener when component unmounts
		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	const scrollToTop = () => {
		// Scroll to the top of the page
		window.scrollTo({ top: 0, behavior: "smooth" })
	}

	return (
		<>
			{showScrollButton && (
				<ScrollToTopIcon mode={mode} handleClick={scrollToTop} />
			)}
		</>
	)
}

export default ScrollToTopButton
