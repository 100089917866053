import LeftSection from "./secondary-view/LeftSection"
import MiddleSection from "./secondary-view/MiddleSection"
import RightSection from "./secondary-view/RightSection"

import { Box, Typography } from "@mui/material"
import { XIcon } from "./shared/Icons"
import { useSelector } from "react-redux"
import RelatedContainer from "./book-details/RelatedContainer"
import ImageContainer from "./book-details/ImageContainer"
import DetailContainer from "./book-details/DetailContainer"

import { useEffect, useRef } from "react"

const PreviewView = ({ book, onClose, relatedBooks }) => {
	const mode = useSelector((state) => state.layout.mode)
	const categoryTags = useSelector((state) => state.submitBook.categoryTags)
	const ref = useRef()

	book.categoryTags = categoryTags

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			onClose()
		}
	}

	const boxShadow =
		mode === "light"
			? "0 0 10px rgba(0, 0, 0, 0.3)"
			: "0 0 10px rgba(255, 255, 255, 0.3)"

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [])


	return (
		<Box
			sx={{
				// Outer wrapper with backdrop filter for blur effect
				position: "fixed", // Use fixed positioning for overlay
				top: "0", 
				left: "0", 
				width: "100%", 
				height: "100%",
				background: "rgba(0, 0, 0, 0.5)", // Add a semi-transparent overlay
				backdropFilter: "blur(10px)", // Apply blur effect to the background
				zIndex: 999, // Ensures the blur overlay is on top of everything
			}}
		>
		<Box
			sx={{
				backgroundColor: "background.default",
				boxShadow,
				
			}}
			position="absolute"
			top="5%"
			left="5%"
			width="90%"
			height="90%%"
			padding="30px"
			zIndex={100}
			ref={ref}
			
		>
			<Box
				width="100%"
				display="flex"
				justifyContent="space-between"
				paddingBottom="2px"
			>
				<Box />
				<Typography>Preview</Typography>
				<XIcon mode={mode} handleClick={onClose} />
			</Box>
			<Box display="flex" borderBottom={1} borderTop={1}>
				<LeftSection
					component={<RelatedContainer relatedBooks={relatedBooks} />}
				/>
				<MiddleSection component={<ImageContainer book={book} />} />
				<RightSection component={<DetailContainer book={book} />} />
			</Box>
		</Box>
		</Box>
	)
}

export default PreviewView