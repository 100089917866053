import { Box, Typography } from "@mui/material"
import { XIcon } from "./Icons"
import { useSelector } from "react-redux"
import { truncateString } from "./truncateString"

const TagItem = ({ text, handleRemove }) => {
	const mode = useSelector((state) => state.layout.mode)

	return (
		<Box
			padding={"1px"}
			paddingLeft={"8px"}
			border={2}
			display={"flex"}
			alignItems={"center"}
			width={"fit-content"}
			whiteSpace={"nowrap"}
		>
			<Typography>{truncateString(text, 30, 30)}</Typography>
			<XIcon mode={mode} handleClick={() => handleRemove(text)}></XIcon>
		</Box>
	)
}

export default TagItem
