import { Box } from "@mui/material"
import { useSelector } from "react-redux"

import { GridIcon, ListIcon } from "../shared/Icons"
import DarkLightSelector from "./DarkLightSelector"
import GridSizeSelector from "./GridSizeSelector"

const LayoutBar = () => {
	const mode = useSelector((state) => state.layout.mode)

	return (
		<Box className="layout" width={"33.4%"}>
			<Box
				width={"33.3%"}
				textAlign={"center"}
				display={"flex"}
				justifyContent={"center"}
				borderRight={1}
			>
				<GridIcon mode={mode} />
				<ListIcon mode={mode} />
			</Box>
			<DarkLightSelector />
			<GridSizeSelector />
		</Box>
	)
}

export default LayoutBar
