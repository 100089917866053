import { Grid } from "@mui/material"
import BookCard from "./BookCard"
import { forwardRef, useEffect, useState } from "react"
import useIsMobile from "../shared/useIsMobile"

const GridContainer = forwardRef(
	({ lastElementRef, data, gridSize }, ref) => {
		const [conf, setConf] = useState()
		const isMobile = useIsMobile()

		useEffect(()=> {

			const breakpoints = {
				1: { xl: 12, lg: 12, md: 12, xs: 12 },
				2: { xl: 6, lg: 6, md: 12, xs: 12 },
				3: { xl: 4, lg: 4, md: 6, xs: 12 },
				4: { xl: 3, lg: 3, md: 4, xs: 6 },
				5: { xl: 2.4, lg: 2.4, md: 4, xs: 12 },
			}
			const mobileBreakpoints = {
				1: { xl: 12, lg: 12, md: 12, xs: 12 },
				2: { xl: 3, lg: 3, md: 4, xs: 6 },
			}
			if (isMobile) {
				setConf(mobileBreakpoints[gridSize])
			}else{
				setConf(breakpoints[gridSize])
			}

			console.log("brakpoints", gridSize)
		}, [gridSize])

		
		if (data) {
			return (
				<>
					<Grid ref={ref} container alignItems="stretch">
						{data.books.map((book, index) => {
							const isRightEdge = (index + 1) % gridSize === 0
							const isLastElement = index === data.books.length - 1
					
							// Determine if we should hide the right border
							const hideRightBorder = isRightEdge && !isLastElement
							return (
							<Grid key={index} className="book-card-item" item {...conf}>
						
								{index === data.books.length - 1 ? (
									<BookCard
										index={index}
										ref={lastElementRef}
										book={book}
										gridSize={gridSize}
										hideRightBorder={hideRightBorder}
									></BookCard>
								) : (
									<BookCard
										index={index}
										book={book}
										gridSize={gridSize}
										hideRightBorder={hideRightBorder}
									></BookCard>
								)}
							</Grid>
							)
		})}
					
					
					</Grid>
				</>
			)
		}
	}
)
GridContainer.displayName = "Gridcontainer"
export default GridContainer
