import { Grid } from "@mui/material"
import BookListItem from "./BookListItem"

const ListContainer = ({ data, lastElementRef }) => {
	if (data) {
		return (
			<Grid container>
				{data.books.map((book, index) => (
					<BookListItem
						key={index}
						ref={lastElementRef}
						book={book}
					></BookListItem>
				))}
			</Grid>
		)
	} else {
		return null
	}
}

export default ListContainer
